import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL_CONSTANTS } from '../../../constants/apiUrlConstants';
import {
  mob_win_23
} from '../../../constants/iconImageConstant';
import { Header } from '../../Header/Header';
import { Link } from 'react-router-dom'; 
import { Footer } from '../../appFooter/Footer';

interface SessionDetail {
  id: string;
  name: string;
  image: string;
  drawing: string;
  position: string;
}

export function MobPainwinList2022() {

  const [responseA, setResponseA] = useState<SessionDetail[]>([]);
  const [responseB, setResponseB] = useState<SessionDetail[]>([]);
  const [selectedDrawing, setSelectedDrawing] = useState<string | null>(null);
  const [selectedDrawingName, setSelectedDrawingName] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [currentYear, setCurrentYear] = useState<string>('2022');

  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;

  useEffect(() => {
    fetchPaintathonResult(currentYear);
  }, [currentYear]);

  const fetchPaintathonResult = useCallback( async (year: string) => {
   
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const data = res.data;
      setResponseA(data.A || []);
      setResponseB(data.B || []);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
    }, [url]);


  const handleClick = (drawing: string, name: string) => {
    setSelectedDrawing(drawing);
    setSelectedDrawingName(name);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDrawing(null);
    setSelectedDrawingName(null);
  };

  const ImageGroup = ({ title, group }: { title: string; group: SessionDetail[] }) => (
    <>
      <h1>{title}</h1>
      <div className="win-container">
        <ul>
          {group.map((item) => (
            <li key={item.id} onClick={() => handleClick(item.drawing, item.name)}>
              <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div className="container-fluid mob-pain-winlist">
        <div className="col-md-12">
          <div className="mob-pain-yrs21">
            <div className="yer-2021">
              <img src={mob_win_23} alt={`Paintathon ${currentYear}`} width="100%" />
            </div>
            <div className="pain-winlist-box">
              <div className="col-md-12">
                <span className="winlis-tit">Winner's List</span>
              </div>
              {responseA.length > 0 && (
              <ImageGroup title="Group A" group={responseA.slice(0, 3)} />
            )}
            {responseB.length > 0 && (
              <ImageGroup title="Group B" group={responseB.slice(0, 3)} />
            )}
              <div className="col-md-12">
                <div className="artgal">
                  <Link to="paintathon-artist-gallerylist-2022">Artist Gallery</Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Drawing */}
        {showModal && (
          <div className="modal fade show"  style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={closeModal}>
            <div className="modal-dialog" role="document">
              <div className="modal-content new-body">
                <div className="modal-header new-mod-head">
                  <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    {selectedDrawing && (
                      <>
                        <img src={`https://assets.etvbalbharat.com/${selectedDrawing}`} width="100%" alt="Drawing" />
                        {selectedDrawingName && (
                          <div className="drw-cand-name">{selectedDrawingName}</div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer/>
    </>
  );
}






















































































































