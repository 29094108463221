import React, { useEffect, useState } from 'react';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';
import {
  fetchWinners,
  Winner,
} from '../../../services/conanContest/conanService';
import {
  mob_conan_Win_List,
} from '../../../constants/iconImageConstant';

export function MobconanWinList() {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedWinner, setSelectedWinner] = useState<Winner | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
 

  useEffect(() => {
    const getWinners = async () => {
      try {
        const data = await fetchWinners();
        setWinners(data);
      } catch (err) {
        setError('Failed to fetch winners');
      } finally {
        setLoading(false);
      }
    };

    getWinners();
  }, []);


  const getGroupLabel = (ageGroup: string) => {
    switch (ageGroup) {
      case 'GROUP A(0-10)':
        return 'Group A';
      case 'GROUP B(11-15)':
        return 'Group B';
      case 'GROUP C(16-20)':
        return 'Group C';
      default:
        return '';
    }
  };

  const handleWinnerClick = (winner: Winner) => {
    setSelectedWinner(winner);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedWinner(null);
  };

  const groupedWinners = winners.reduce((acc, winner) => {
    const group = getGroupLabel(winner.age);
    if (!acc[group]) acc[group] = [];
    acc[group].push(winner);
    return acc;
  }, {} as Record<string, Winner[]>);

  return (
    <>
      <Header />

      <div className="container-fluid mob-conanbg">
        <div className="row">
          <div className="col-md-12">
            <div className="mob-pain-nxtpg-cont">
              <img src={mob_conan_Win_List} alt="Conan" width="100%" />
              {loading ? (
                <div>Loading...</div>
              ) : error ? (
                <div>{error}</div>
              ) : Object.keys(groupedWinners).length > 0 ? (
                <>
                  {/* Render Group A */}
                  {groupedWinners['Group A'] &&
                    groupedWinners['Group A'].length > 0 && (
                      <div className="mob-conan-win-box">
                        <div className="gp-tit">Group A</div>
                        {groupedWinners['Group A'].map((winner) => (
                          <div key={winner.id}>
                            <div className="win-candidatenm">{winner.name}</div>
                            <div className="conan-gpa">
                              <a
                                href="#"
                                onClick={() => handleWinnerClick(winner)}
                              >
                                <img
                                  src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
                                  alt={`${winner.name}s photo`}
                                  width="100%"
                                />
                              </a>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                        ))}
                      </div>
                    )}

                  {/* Render Other Groups */}
                  <div className="mob-gps">
                    <div className="col-10 offset-1">
                      <ul>
                        {/* Render Group B */}
                        {groupedWinners['Group B'] &&
                          groupedWinners['Group B'].length > 0 && (
                            <li>
                              <div className="gp-tit">Group B</div>
                              {groupedWinners['Group B'].map((winner) => (
                                <div key={winner.id}>
                                  <div className="win-candidatenm">
                                    {winner.name}
                                  </div>
                                  <div className="conan-gpa">
                                    <a
                                      href="#"
                                      onClick={() => handleWinnerClick(winner)}
                                    >
                                      <img
                                        src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
                                        alt={`${winner.name}`}
                                        width="100%"
                                      />
                                    </a>
                                  </div>
                                  <div className="clearfix"></div>
                                </div>
                              ))}
                            </li>
                          )}

                        {/* Render Group C */}
                        {groupedWinners['Group C'] &&
                          groupedWinners['Group C'].length > 0 && (
                            <li>
                              <div className="gp-tit">Group C</div>
                              {groupedWinners['Group C'].map((winner) => (
                                <div key={winner.id}>
                                  <div className="win-candidatenm">
                                    {winner.name}
                                  </div>
                                  <div className="conan-gpa">
                                    <a
                                      href="#"
                                      onClick={() => handleWinnerClick(winner)}
                                    >
                                      <img
                                        src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
                                        // alt={`${winner.name}'s photo`}
                                        alt={`${winner.name}s photo`}
                                        width="100%"
                                      />
                                    </a>
                                  </div>
                                  <div className="clearfix"></div>
                                </div>
                              ))}
                            </li>
                          )}
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <div>No winners available.</div>
              )}
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>

      {/* Modal for showing video */}
      {showModal && selectedWinner && (
        <div
          className="modal fade show"
          style={{ display: 'block' }}
          role="dialog"
          onClick={closeModal}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content modal-bg">
              <div className="modal-header modal-he-adj">
                <button
                  type="button"
                  className="close close-bg"
                  onClick={closeModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body nopd">
                <div className="drws">
                  <div className="embed-responsive embed-responsive-4by3">
                    <video
                      width="100%"
                      height="auto"
                      controls
                      src={`https://assets.etvbalbharat.com/conan_contest/${selectedWinner.video}`}
                    >
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div className="drw-cand-name">{selectedWinner.name}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}










// There place  audio icon  add in this code ............
 

// import React, { useEffect, useState } from 'react';
// import { Header } from '../../Header/Header';
// import { Footer } from '../../appFooter/Footer';
// import {
//   fetchWinners,
//   Winner,
// } from '../../../services/conanContest/conanService';
// import {
//   mob_conan_Win_List,
//   volumeIcon,
//   muteIcon,
// } from '../../../constants/iconImageConstant';

// export function MobconanWinList() {
//   const [winners, setWinners] = useState<Winner[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [selectedWinner, setSelectedWinner] = useState<Winner | null>(null);
//   const [showModal, setShowModal] = useState<boolean>(false);
//   const [isMuted, setIsMuted] = useState<boolean>(false);
//   const audioRef = React.useRef<HTMLAudioElement>(null);

//   useEffect(() => {
//     const getWinners = async () => {
//       try {
//         const data = await fetchWinners();
//         setWinners(data);
//       } catch (err) {
//         setError('Failed to fetch winners');
//       } finally {
//         setLoading(false);
//       }
//     };

//     getWinners();
//   }, []);

//   const handleMuteToggle = () => {
//     if (audioRef.current) {
//       audioRef.current.muted = !isMuted;
//       setIsMuted(!isMuted);
//     }
//   };

//   const getGroupLabel = (ageGroup: string) => {
//     switch (ageGroup) {
//       case 'GROUP A(0-10)':
//         return 'Group A';
//       case 'GROUP B(11-15)':
//         return 'Group B';
//       case 'GROUP C(16-20)':
//         return 'Group C';
//       default:
//         return '';
//     }
//   };

//   const handleWinnerClick = (winner: Winner) => {
//     setSelectedWinner(winner);
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setSelectedWinner(null);
//   };

//   const groupedWinners = winners.reduce((acc, winner) => {
//     const group = getGroupLabel(winner.age);
//     if (!acc[group]) acc[group] = [];
//     acc[group].push(winner);
//     return acc;
//   }, {} as Record<string, Winner[]>);

//   return (
//     <>
//       <Header />
//       <audio id="myAudio" autoPlay ref={audioRef}>
//         <source
//           src="https://assets.etvbalbharat.com/video/DCmusic.mp3"
//           type="audio/mp3"
//         />
//       </audio>

//       <div className="container-fluid mob-conanbg">
//         <div className="row">
//           <div className="col-md-12">
//             <div className="mob-pain-nxtpg-cont">
//               <img src={mob_conan_Win_List} alt="Conan" width="100%" />
//               {loading ? (
//                 <div>Loading...</div>
//               ) : error ? (
//                 <div>{error}</div>
//               ) : Object.keys(groupedWinners).length > 0 ? (
//                 <>
//                   {/* Render Group A */}
//                   {groupedWinners['Group A'] &&
//                     groupedWinners['Group A'].length > 0 && (
//                       <div className="mob-conan-win-box">
//                         <div className="gp-tit">Group A</div>
//                         {groupedWinners['Group A'].map((winner) => (
//                           <div key={winner.id}>
//                             <div className="win-candidatenm">{winner.name}</div>
//                             <div className="conan-gpa">
//                               <a
//                                 href="#"
//                                 onClick={() => handleWinnerClick(winner)}
//                               >
//                                 <img
//                                   src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
//                                   alt={`${winner.name}s photo`}
//                                   width="100%"
//                                 />
//                               </a>
//                             </div>
//                             <button
//                               className="audio"
//                               id="muteButton"
//                               onClick={handleMuteToggle}
//                             >
//                               <img
//                                 id="muteIcon"
//                                 src={isMuted ? muteIcon : volumeIcon}
//                                 alt="Mute/Unmute Icon"
//                               />
//                             </button>
//                             <div className="clearfix"></div>
//                           </div>
//                         ))}
//                       </div>
//                     )}

//                   {/* Render Other Groups */}
//                   <div className="mob-gps">
//                     <div className="col-10 offset-1">
//                       <ul>
//                         {/* Render Group B */}
//                         {groupedWinners['Group B'] &&
//                           groupedWinners['Group B'].length > 0 && (
//                             <li>
//                               <div className="gp-tit">Group B</div>
//                               {groupedWinners['Group B'].map((winner) => (
//                                 <div key={winner.id}>
//                                   <div className="win-candidatenm">
//                                     {winner.name}
//                                   </div>
//                                   <div className="conan-gpa">
//                                     <a
//                                       href="#"
//                                       onClick={() => handleWinnerClick(winner)}
//                                     >
//                                       <img
//                                         src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
//                                         alt={`${winner.name}`}
//                                         width="100%"
//                                       />
//                                     </a>
//                                   </div>
//                                   <div className="clearfix"></div>
//                                 </div>
//                               ))}
//                             </li>
//                           )}

//                         {/* Render Group C */}
//                         {groupedWinners['Group C'] &&
//                           groupedWinners['Group C'].length > 0 && (
//                             <li>
//                               <div className="gp-tit">Group C</div>
//                               {groupedWinners['Group C'].map((winner) => (
//                                 <div key={winner.id}>
//                                   <div className="win-candidatenm">
//                                     {winner.name}
//                                   </div>
//                                   <div className="conan-gpa">
//                                     <a
//                                       href="#"
//                                       onClick={() => handleWinnerClick(winner)}
//                                     >
//                                       <img
//                                         src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
//                                         // alt={`${winner.name}'s photo`}
//                                         alt={`${winner.name}s photo`}
//                                         width="100%"
//                                       />
//                                     </a>
//                                   </div>
//                                   <div className="clearfix"></div>
//                                 </div>
//                               ))}
//                             </li>
//                           )}
//                       </ul>
//                     </div>
//                   </div>
//                 </>
//               ) : (
//                 <div>No winners available.</div>
//               )}
//             </div>
//           </div>
//         </div>
//         <div className="clearfix"></div>
//       </div>

//       {/* Modal for showing video */}
//       {showModal && selectedWinner && (
//         <div
//           className="modal fade show"
//           style={{ display: 'block' }}
//           role="dialog"
//           onClick={closeModal}
//         >
//           <div className="modal-dialog modal-dialog-centered" role="document">
//             <div className="modal-content modal-bg">
//               <div className="modal-header modal-he-adj">
//                 <button
//                   type="button"
//                   className="close close-bg"
//                   onClick={closeModal}
//                   aria-label="Close"
//                 >
//                   <span aria-hidden="true">&times;</span>
//                 </button>
//               </div>
//               <div className="modal-body nopd">
//                 <div className="drws">
//                   <div className="embed-responsive embed-responsive-4by3">
//                     <video
//                       width="100%"
//                       height="auto"
//                       controls
//                       src={`https://assets.etvbalbharat.com/conan_contest/${selectedWinner.video}`}
//                     >
//                       Your browser does not support the video tag.
//                     </video>
//                   </div>
//                   <div className="drw-cand-name">{selectedWinner.name}</div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       <Footer />
//     </>
//   );
// }

















