import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { mob_win_24 } from '../../../constants/iconImageConstant';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';
import { Link } from 'react-router-dom';
import { API_URL_CONSTANTS } from '../../../constants/apiUrlConstants';

interface WinnerDetail {
  id: string;
  name: string;
  image: string;
  drawing: string;
  position: string;
  MEGA?: WinnerDetail;
}

export function MobPainwinList2024() {
  const [responseA, setResponseA] = useState<WinnerDetail[]>([]);
  const [responseB, setResponseB] = useState<WinnerDetail[]>([]);
  const [responseC, setResponseC] = useState<WinnerDetail[]>([]);
  const [megaWinner, setMegaWinner] = useState<WinnerDetail | null>(null);
  const [selectedDrawing, setSelectedDrawing] = useState<string | null>(null);
  const [selectedDrawingName, setSelectedDrawingName] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const selectedYear = '2024';

  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;

  useEffect(() => {
    fetchPaintathonResult(selectedYear);
  }, [selectedYear]);

  const fetchPaintathonResult = useCallback(async (year: string) => {
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const data = res.data;
      setResponseA(data.A || []);
      setResponseB(data.B || []);
      setResponseC(data.C || []);
      setMegaWinner(data.MEGA || null); 
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  }, [url]);

  const handleClick = (drawing: string, name: string) => {
    setSelectedDrawing(drawing);
    setSelectedDrawingName(name);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDrawing(null);
    setSelectedDrawingName(null);
  };


  const ImageGroup = ({
    title,
    group,
  }: {
    title: string;
    group: WinnerDetail[];
  }) => (
    <>
      <h1>{title}</h1>
      <div className="mega-win-container">
        <ul>
          {group.map((item) => (
            <li
              key={item.id}
              onClick={() => handleClick(item.drawing, item.name)}
            >
              <img
                src={`https://assets.etvbalbharat.com/${item.image}`}
                alt={item.name}
                width="100%"
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  
  return (
    <>
      <Header />
      <div className="container-fluid mob-pain-winlist">
        <div className="row">
          <div className="col-md-12">
            <div className="mob-pain-yrs21">
              <div className="yer-2024">
                <Link to="paintathon-drawing-competition-2024">
                  <img
                    src={mob_win_24}
                    alt="Paintathon 2024 Background"
                    width="100%"
                  />
                </Link>
              </div>

              <div className="pain-winlist-box23">
                <div className="col-md-12">
                  <span className="winlis-tit">Winner's List</span>
                </div>

                {megaWinner && (
                  <div
                    onClick={() =>
                      handleClick(megaWinner.drawing, megaWinner.name)
                    }
                  >
                    <h1>Mega Winner</h1>
                    <div className="mega-win">
                    <img
                        src={`https://assets.etvbalbharat.com/${megaWinner.image}`}
                        alt={megaWinner.name }
                        width="100%"
                      />
                    </div>
                  </div>
                )}

                 {responseA.length > 0 && (
                  <ImageGroup title="Group A" group={responseA.slice(0, 3)} />
                )}
                {responseB.length > 0 && (
                  <ImageGroup title="Group B" group={responseB.slice(0, 3)} />
                )}
                {responseC.length > 0 && (
                  <ImageGroup title="Group C" group={responseC.slice(0, 3)} />
                )}


                {/* Artist Gallery Link */}
                <div className="col-md-12">
                  <div className="artgal">
                    <Link to="paintathon-artist-gallerylist-2024">
                      Artist Gallery
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Drawing */}
        {showModal && (
          <div
            className="modal fade show"
            style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onClick={closeModal}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content new-body">
                <div className="modal-header new-mod-head">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={closeModal}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="container">
                    {selectedDrawing && (
                      <>
                        <img
                          src={`https://assets.etvbalbharat.com/${selectedDrawing}`}
                          width="100%"
                          alt="Drawing"
                        />
                        {selectedDrawingName && (
                          <div className="drw-cand-name">
                            {selectedDrawingName}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}






