
export const ROUTER_URL_CONSTANT = {
  MAIN: '/',
  LOGIN: '/login',
  REGISTER: '/register',
  VERIFY_MOBILE: '/verifyMobile',
  FORGOT_PASSWORD: '/forgotpwd',
  NEW_PASSWORD: '/newpwd',
  PROFILE_BUILDER: '/profilebuilder',
  PROFILE_DETAILS: '/profiledetails',
  HOME: '/home',
  HOME_NOTIFICATIONS: '/homeNotifications',
  HOME_SEARCH: '/search',
  HOME_LANGUAGES: '/homeLanguages',
  MENU: '/menu',
  ACCOUNT: '/account',
  EDIT_ACCOUNT: '/editaccount',
  EDIT_CHILD_ACCOUNT: '/editchildaccount',
  UPDATE_MOBILE: '/updatemobile',
  ACCOUNT_CHANGE_PASSWORD: '/accountChangePassword',
  ACCOUNT_FORGOT_PASSWORD: '/accountForgotPassword',
  TV_SHOWS: '/Shows',
  ORIGINALS: '/tvShows/originals',
  ORIGINALS_DETAILS: '/tvShows/originals/details',
  GLOBAL: '/tvShows/global',
  GLOBAL_DETAILS: '/tvShows/global/details',
  TODDLERS: '/tvShows/toddlers',
  TODDLERS_DETAILS: '/tvShows/toddlers/details',
  EDUTAINMENT: '/tvShows/edutainment',
  EDUTAINMENT_DETAILS: '/tvShows/edutainment/details',
  MOVIES: '/movies',
  MOVIES_DETAILS: '/movies/details',
  MY_BB: '/mybb',
  MY_BB_CLUB: '/myBbClub',
  MY_BB_ALLEVENT: '/mybb-all-events',
  MY_BB_EVENT_DETAIL: '/mybb-event-detail',
  MY_BB_EVENT_CLOSED: '/mybb-event-closed',
  DISCLAIMER: '/disclaimer',
  ABOUT_US: '/About us',
  PRIVACY_POLICY: '/privacyPolicy',
  TERMS_AND_CONDITIONS: '/termsAndConditions',
  COOKIE_POLICY: '/cookiePolicy',
  TRADEMARKS_AND_IPS: '/trademarksAndIps',
  MENU_LANGUAGES: '/menuLanguages',
  FEEDBACK: '/feedback',
  HELP_AND_SUPPORT: '/helpAndSupport',
  SUB_SETTINGS: '/subSettings',
  SUB_SETTINGS_VIDEO: '/subSettingsVideo',
  SUB_SETTINGS_TIMER: '/subSettingsTimer',
  SUB_SETTINGS_NOTIFICATION: '/subSettingsNotification',
  My_FAVOURITES: '/myFavourites',
  SWITCH_PROFIEL: '/switchProfile',
  NOTIFICATIONS: '/notifications',
  CHANGE_PIN: '/changePin',
  TIMER_SETTINGS: '/timerSettings',
  WATCH_HISTORY: '/watchHistory',
  CREATE_PIN: '/createPin',
  ENTER_PIN: '/enterPin',
  FORGOT_PIN: '/forgotPin',
  RESET_PIN: '/resetPin',
  LANDING_PAGE: '/landingPage',
  TOONS_DETAIL: '/toonsDetail',
  SUBSCRIPTION: '/subscription',
  LIST_OF_ITEMS: '/listOfItems',
  LANGUAGE_SELECTION: '/languageSelection',
  CHANNEL_SCHEDULE: '/channelschedule',
  CONTACTUS: '/contactus',
  EVENTS: '/events',
  PAINTATHON_WINNER_LIST:'/paintathon-drawing-competition',
  CANON_WINNER_LIST:'/canon_winnerlist',
  MOB_PAINT_WIN_LIST_2021:'/paintathon-drawing-competition-2021',
  MOB_PAINT_WIN_LIST_2022:'/paintathon-drawing-competition-2022',
  MOB_PAINT_WIN_LIST_2024:'/paintathon-drawing-competition-2024',
  MOB_CONAN_YEAR:'/mob_conan_year_list',
  MOB_CANON_WINNER_LIST :'mob_canon_winnerlist',
  PAINT_WIN_LIST_2021:'/paintathon-drawing-competition-2021',
  PAINT_WIN_LIST_2022:'/paintathon-drawing-competition-2022',
  PAINT_WIN_LIST_2024:'/paintathon-drawing-competition-2024',
};

export const APP_CONST = {
  USER_SESSION: 'userSession',
};
