import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL_CONSTANTS } from '../../../constants/apiUrlConstants';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';
import { paintbrush } from '../../../constants/iconImageConstant';
import { Link } from 'react-router-dom';

interface ImageDetail {
  id: string;
  name: string;
  image: string;
  age: string;
  drawing: string;
}

export function ArtistGallery_2021() {
  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;
  const [images, setImages] = useState<ImageDetail[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState<ImageDetail | null>(
    null
  );

  useEffect(() => {
    fetchArtistGalleryImages('2021');
  }, []);

  const fetchArtistGalleryImages = async (year: string) => {
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const responseData_A = res.data?.A || [];
      const responseData_B = res.data?.B || []; 
      // added there slice method remove group first 3 images 11/12/2024
      const groupA = responseData_A.filter((item: ImageDetail) =>
        item.age.includes('Group A')
      ).slice(3);
      const groupB = responseData_B.filter((item: ImageDetail) =>
        item.age.includes('Group B')
      ).slice(3);
       
      const combinedData = [...groupA, ...groupB];

      setImages(combinedData);

    } catch (error) {
      console.error('Error fetching artist gallery images:', error);
    }
  };


  const handleImageClick = (drawing: ImageDetail) => {
    setSelectedDrawing(drawing);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDrawing(null);
  };

  return (
    <>
      <Header />
      <div className="container-fluid page-bgs">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="pain-win-yrs">
                <div className="win-list-box">
                  <div className="pain-yrs zoombt yrs21">
                    <Link to="paintathon-drawing-competition-2021">2021</Link>
                  </div>
                  <div className="pain-yrs zoombt">
                    <Link to="paintathon-drawing-competition-2022">2022</Link>
                  </div>
                  <div className="pain-yrs zoombt">
                    <Link to="paintathon-drawing-competition-2022">2024</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="pain-win-list-21">
                <div className="mega-win-1st list-22 wd">
                  <div className="win-tit">Artist Gallery</div>

                  <div className="col-md-6 offset-md-3 col-sm-6 offset-sm-3 mb-3 nopd">
                    <div className="row arilist">
                    {images.map((item) => (
                        <div className="col-md-4 col-sm-4 artbox" key={item.id}>
                          <div
                            className="gropbox Artisbox"
                            onClick={() => handleImageClick(item)}
                          >
                            <img
                              src={`https://assets.etvbalbharat.com/${item.image}`}
                              alt={item.name}
                              width="100%"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="paintbrush-bk-main">
              <div className="paintboxmain">
                <img src={paintbrush} alt="paintbrush" />
              </div>
              <div className="bk-btn zoombt">
                <a href="/events">Back to Main Menu</a>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && selectedDrawing && (
        <div
          className="modal fade show"
          style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          onClick={closeModal}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content paintathon-modal-bg">
              <div className="modal-header modal-he-adj">
                <button
                  type="button"
                  className="close close-bg"
                  onClick={closeModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body nopd">
                <div className="drws">
                  <img
                    src={`https://assets.etvbalbharat.com/${selectedDrawing.drawing}`}
                    width="100%"
                    alt="Drawing"
                  />
                  <div className="drw-cand-name">{selectedDrawing.name}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}












// comment this code not changes anythink ....07/12/2024  old code 



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { API_URL_CONSTANTS } from '../../../constants/apiUrlConstants';
// import { Header } from '../../Header/Header';
// import { Footer } from '../../appFooter/Footer';
// import { paintbrush } from '../../../constants/iconImageConstant';

// interface ImageDetail {
//   id: string;
//   name: string;
//   image: string;
//   drawing: string;
// }

// export function ArtistGallery_2021() {
//   const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;
//   const [images, setImages] = useState<ImageDetail[]>([]);
//   const [showModal, setShowModal] = useState(false);
//   const [selectedDrawing, setSelectedDrawing] = useState<ImageDetail | null>(
//     null
//   );

//   useEffect(() => {
//     fetchArtistGalleryImages('2021');
//   }, []);

//   const fetchArtistGalleryImages = async (year: string) => {
//     try {
//       const res = await axios.post(
//         url,
//         { params: { year } },
//         { headers: { 'Content-Type': 'application/json' } }
//       );
//       if (Array.isArray(res.data)) {
//         setImages(res.data);
//       } else if (res.data && Array.isArray(res.data.results)) {
//         setImages(res.data.results);
//       } else {
//         setImages([]);
//       //   console.warn('Unexpected response format:', res.data);
//        }
//     } catch (error) {
//       // console.log('Error fetching artist gallery images:', error);
//     }
//   };
  

//   const handleImageClick = (drawing: ImageDetail) => {
//     setSelectedDrawing(drawing);
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setSelectedDrawing(null);
//   };

//   return (
//     <>
//       <Header />
//       <div className="container-fluid page-bgs">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-6">
//               <div className="pain-win-yrs">
//                 <div className="win-list-box">
//                   <div className="pain-yrs zoombt yrs21">
//                     <a href="/paintathon-drawing-competition-2021">2021</a>
//                   </div>
//                   <div className="pain-yrs zoombt">
//                     <a href="/paintathon-drawing-competition-2022">2022</a>
//                   </div>
//                   <div className="pain-yrs zoombt">
//                     <a href="/paintathon-drawing-competition-2022">2024</a>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-6">
//               <div className="pain-win-list-21">
//                 <div className="mega-win-1st list-22 wd">
//                   <div className="win-tit">Artist Gallery</div>

//                   <div className="col-md-6 offset-md-3 col-sm-6 offset-sm-3 mb-3 nopd">
//                     <div className="row arilist">
//                       {images.map((item) => (
//                         <div className="col-md-4 col-sm-4 artbox" key={item.id}>
//                           <div
//                             className="gropbox Artisbox"
//                             onClick={() => handleImageClick(item)}
//                           >
//                             <img
//                               src={`https://assets.etvbalbharat.com/${item.image}`}
//                               alt={item.name}
//                               width="100%"
//                             />
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               <div className="paintbrush-bk-main">
//               <div className="paintboxmain">
//                 <img src={paintbrush} alt="paintbrush" />
//               </div>
//               <div className="bk-btn zoombt">
//                 <a href="/events">Back to Main Menu</a>
//               </div>
//             </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {showModal && selectedDrawing && (
//         <div
//           className="modal fade show"
//           style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
//           role="dialog"
//           aria-labelledby="exampleModalCenterTitle"
//           aria-hidden="true"
//           onClick={closeModal}
//         >
//           <div className="modal-dialog modal-dialog-centered" role="document">
//             <div className="modal-content paintathon-modal-bg">
//               <div className="modal-header modal-he-adj">
//                 <button
//                   type="button"
//                   className="close close-bg"
//                   onClick={closeModal}
//                   aria-label="Close"
//                 >
//                   <span aria-hidden="true">&times;</span>
//                 </button>
//               </div>
//               <div className="modal-body nopd">
//                 <div className="drws">
//                   <img
//                     src={`https://assets.etvbalbharat.com/${selectedDrawing.drawing}`}
//                     width="100%"
//                     alt="Drawing"
//                   />
//                   <div className="drw-cand-name">{selectedDrawing.name}</div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       <Footer />
//     </>
//   );
// }


































