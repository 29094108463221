export const GET_AVATAR_LIST_ACTION = 'GET_AVATAR_LIST_ACTION';
export const GET_PREFERENCES_LIST_ACTION = 'GET_PREFERENCES_LIST_ACTION';

export const GET_KID_PROFILE_ACTION = 'GET_KID_PROFILE_ACTION';
export const GET_KID_PROFILE_SUCCESS_ACTION = 'GET_KID_PROFILE_SUCCESS_ACTION';
export const GET_KID_PROFILE_FAILURE_ACTION = 'GET_KID_PROFILE_FAILURE_ACTION';

export const SET_KID_REGISTRATION_ACTION = 'SET_KID_REGISTRATION_ACTION';
export const SET_KID_REGISTRATION_SUCCESS_ACTION =
  'SET_KID_REGISTRATION_SUCCESS_ACTION';
export const SET_KID_REGISTRATION_FAILURE_ACTION =
  'SET_KID_REGISTRATION_FAILURE_ACTION';
export const SET_TAB_INDEX = 'SET_TAB_INDEX';
export const SET_PROFILE_BUILDER_DATA = 'SET_PROFILE_BUILDER_DATA';
export const ADD_NEW_PROFILE_BUILDER_DATA = 'ADD_NEW_PROFILE_BUILDER_DATA';
export const EDIT_PROFILE_BUILDER_DATA = 'EDIT_PROFILE_BUILDER_DATA';

export const UPDATE_KID_REGISTRATION_SUCCESS_ACTION =
  'UPDATE_KID_REGISTRATION_SUCCESS_ACTION';
export const UPDATE_KID_REGISTRATION_FAILURE_ACTION =
  'UPDATE_KID_REGISTRATION_FAILURE_ACTION';
