//added new code there place today responsive header 04/10/2024  05:18PM onwords,

import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Nav, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  avatarHeader,
  HiImgNew,
  newHomePageSearch,
  notificationBellWhite,
  newHomePageMovies,
  newHomePageHTvShows,
  ResponsiveHomeIcon,
  ResponsiveMoveIcon,
  ResponsiveTvIcon,
  // newHomePageEventIcon,
  ResponsiveSearchIcon,
  aboutActive,
  aboutInActive,
  defaultLogo,
} from '../../constants/iconImageConstant';
import { ROUTER_URL_CONSTANT } from '../../constants/routerUrlConstant';
import { selectors as userStoreSelectors } from '../../store/userStore';
import { Search } from '../search/Search';
import { notificationsCount } from '../../services/notificationsCount/notificationsCount';
import { selectors as notificationsCountSelectors } from '../../store/notificationsCount';
import { selectors as lables } from '../../store/categoryMaster';
import { selectLanguageSelector } from '../../store/selectLanguage';
import { getItem } from '../../utils/storage';
import { closeIcon} from '../../constants/iconImageConstant';

export const Header: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(['header']);
  const [dynamicLogo, setDynamicLogo] = useState(defaultLogo);
  const [headerPic, setHeaderPic] = useState(avatarHeader);
  const userLang = useSelector(selectLanguageSelector.getSelectLanguageState);
  const [imageSrc, setImageSrc] = useState('');
  const [iconName, setIconName] = useState('');
  const [altText, setAltText] = useState('');

  useEffect(() => {
    if (userLang.lang_image !== '' && userLang.lang_image !== null) {
      setDynamicLogo(userLang.lang_image);
    }
    // BUILD-1
    // else {
    //   setDynamicLogo(dynamicLogo);
    // }
  }, [userLang]);
  const [homeTabSelected, sethomeTabSelected] = useState(false);
  const [tvShowsTabSelected, setTvShowsTabSelected] = useState(false);
  const [moviesTabSelected, setMoviesTabSelected] = useState(false);
  const [myBBTabSelected, setMyBBTabSelected] = useState(false);
  // const [eventTabSelected, setEventTabSelected] = useState(false)
  const [isLogin, setIslogin] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const userStoreState = useSelector(userStoreSelectors.getUserStoreState);
  const labelData = useSelector(lables.getCatagoryMasterState);
  const labelDetails = labelData?.data;
  // const [isMaxScroll, setIsMaxScroll] = useState(false);
  const notificationsCountState = useSelector(
  notificationsCountSelectors.getNotificationsCountState
  );
  const totalNotifications =
    notificationsCountState.data && notificationsCountState.data[0].count;

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.onscroll = () => {
  //       const currentScrollPos = window.pageYOffset;
  //       const maxScroll = document.body.scrollHeight - window.innerHeight;
  //       if (currentScrollPos === maxScroll) {
  //         setIsMaxScroll(true);
  //       } else {
  //         setIsMaxScroll(false);
  //       }
  //     };
  //   }
  // }, []);

  const useEffectOnMount = (effect: React.EffectCallback) => {
  React.useEffect(effect, [userStoreState.data]);
  };
  useEffectOnMount(() => {
    if (userStoreState?.data?.IsverifiedOTP === 'Y') {
      if (getItem('selectedKid')) {
        const kid = getItem('selectedKid');
        setHeaderPic(kid?.avatarimages);
      }
      setIslogin(true);
      const user = userStoreState.data.userid;

      if (user) {
        const params2 = {
          user,
          kidid: user.selectedKidid || '',
          device_id: '',
        };
        dispatch(notificationsCount(params2));
      }
    }
  });

  const urlPathName = history.location.pathname;
  useEffect(() => {
    if (
      urlPathName.includes(ROUTER_URL_CONSTANT.HOME) ||
      urlPathName.includes(ROUTER_URL_CONSTANT.LANGUAGE_SELECTION)
    ) {
      sethomeTabSelected(true);
    } else if (urlPathName.includes(ROUTER_URL_CONSTANT.TV_SHOWS)) {
      setTvShowsTabSelected(true);
    } else if (urlPathName.includes(ROUTER_URL_CONSTANT.MOVIES)) {
      setMoviesTabSelected(true);
    } else if (urlPathName.includes(ROUTER_URL_CONSTANT.MY_BB)) {
      setMyBBTabSelected(true);
    }else if (urlPathName.includes(ROUTER_URL_CONSTANT.MY_BB)) {
    setMyBBTabSelected(true);
    
  } else if (urlPathName.includes(ROUTER_URL_CONSTANT.EVENTS)){
    // setEventTabSelected(true);
  }
  }, [urlPathName]);

  const handleHeaderTabs = (selectedTab) => {
    if (selectedTab === 'HOME') {
      history.push(ROUTER_URL_CONSTANT.HOME);
    } else if (selectedTab === 'SHOWS') {
      history.push(ROUTER_URL_CONSTANT.TV_SHOWS);
    } else if (selectedTab === 'MOVIES') {
      history.push(ROUTER_URL_CONSTANT.MOVIES);
    } else if (selectedTab === 'ABOUT') {
      history.push(ROUTER_URL_CONSTANT.ABOUT_US);
    } else if (selectedTab === 'Event'){
      history.push(ROUTER_URL_CONSTANT.EVENTS)
  
    }
  };

  const handleSearch = () => {
    setShowSearchModal(true);
  };

  const handleLogoClick = () => {
    history.push(ROUTER_URL_CONSTANT.HOME);
  };

  const handleLoginClick = () => {
    history.push(ROUTER_URL_CONSTANT.LOGIN);
  };

  const handleNotificationClick = () => {
    history.push(ROUTER_URL_CONSTANT.HOME_NOTIFICATIONS);
  };
  const handleAvatarClick = () => {
    history.push(ROUTER_URL_CONSTANT.MENU);
  };
  const gotoLanguagePage = () => {
    history.push(ROUTER_URL_CONSTANT.LANGUAGE_SELECTION);
  };
  const [show, setShow] = useState(false);
  // const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  
  const datehh = new Date();
  //alert(datehh);
  const month = (datehh.getMonth() + 1).toString().padStart(2, "0");
  //const datenew='0' + datehh.getDate();
  const datenew = datehh.getDate().toString().padStart(2, "0");
  const yearnw = datehh.getFullYear();
  const yymmdd = yearnw + '' + month + '' + datenew;
  //alert(datenew);
  // alert(yymmdd);
  //Added By MC on 14-08-2021 11:45 for Dahi Handi 2021


  
// diwali sheddule 27/10/2024

  const handleClick = () => {
    if (iconName === 'Diwali Rangowali') {
      window.location.assign('https://mybb.etvbalbharat.com/rangoli/index.php');
    } else if (iconName === 'Birthday Bash') {
      window.location.assign('https://mybb.etvbalbharat.com/kids_birthdates_2023');
    }
  };

    
  const diwali_URL = () => {
    window.location.assign('https://mybb.etvbalbharat.com/rangoli/index.php');
  }

  const dahiURL = () => {
    //alert(datenew+month+yearnw);
    //if((datenew+month+yearnw)>='01102022'){
    //if((yearnw+month+datenew)>='20221001'){
    // if(month === 10 || month === 11){

    //   if(month === 11 && datenew < 14){
    // window.location.assign('https://mybb.etvbalbharat.com/paintathon-2022/validate.php?lang='+userLang.lang_id);
    // }else{

    // }
    if (parseInt(yymmdd) >= 20230701 && parseInt(yymmdd) <= 20230731) {
      window.location.assign('https://mybb.etvbalbharat.com/conan_contest');
    // } else if (parseInt(yymmdd) >= 20240427 && parseInt(yymmdd) <= 20240530) {
    } else if (parseInt(yymmdd) >= 20241027 && parseInt(yymmdd) <= 20241114) {
      window.location.assign('https://mybb.etvbalbharat.com/rangoli/images/pop-up.png');
    } else {
      window.location.assign('https://mybb.etvbalbharat.com/kids_birthdates_2023');
    }
  }

//desktop Logo logic

 useEffect(() => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth(); 

   console.log(month);
    if ((month === 9 && day >= 27) || (month === 10 && day <= 14)) {
      setImageSrc('https://mybb.etvbalbharat.com/rangoli/images/DIYA_GIF_SJ.gif');
      setIconName('Diwali Rangowali');
      setAltText('Diwali - Rangowali Celebration');
       
    } else {
      setImageSrc('https://mybb.etvbalbharat.com/kids_birthdates_2023/images/navi-bday.png');
      setIconName('Birthday Bash');
      setAltText('Birthday celebration image');

    }
  }, []);

  return (
    <div className="header-container">
      <div className="header-container-web">
        <img src={HiImgNew} className="Hi-img" alt="Hi Icon" />
        <Row>
          <Col className="col-8 offset-2 text-center">
            <Nav as="ul" role="menu">

             {/* Added new code related to events page navbar on 14/08/2024 */}
              {/* <Nav.Item 
                as="li"
                className={
                  eventTabSelected ? 'active miniScreens' : 'miniScreens'
                }
              >
                <Nav.Link onClick={() => handleHeaderTabs('EVENT')}>
                  <img src={newHomePageEventIcon} alt="EventsIcon" />
                  <span>
                   Event
                  
                   {labelDetails?.label_details[0]?.EVENT}
                   {labelDetails?.group_details?.[0]?.group_catg_name}


                  </span>
                </Nav.Link>
              </Nav.Item>  */}
              <Nav.Item
                as="li"
                className={
                  tvShowsTabSelected ? 'active miniScreens' : 'miniScreens'
                }
              >
                <Nav.Link onClick={() => handleHeaderTabs('SHOWS')}>
                  <img src={newHomePageHTvShows} alt="TVShowsIcon" />
                  <span>
                    {/* {labelDetails?.group_details?.[0]?.group_catg_name} */}
                    Shows
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={
                  moviesTabSelected ? 'active miniScreens' : 'miniScreens'
                }
              >
                <Nav.Link onClick={() => handleHeaderTabs('MOVIES')}>
                  <img src={newHomePageMovies} alt="MoviesIcon" />
                  <span>
                    {/* {labelDetails?.group_details?.[1]?.group_catg_name} */}
                    Movies
                  </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={`logo ${homeTabSelected ? 'active miniScreens' : 'miniScreens '
                  }`}
              >
                <Nav.Link>
                  <img
                    className="webLogo"
                    src={dynamicLogo}
                    alt="AppLogo"
                    onClick={handleLogoClick}
                    aria-hidden="true"
                  />
                  <div
                    aria-hidden="true"
                    onClick={gotoLanguagePage}
                    className="active-redirect-btn"
                  >
                    &nbsp;
                  </div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="miniScreens">
                <Nav.Link onClick={handleSearch}>
                  <img src={newHomePageSearch} alt="SearchIcon" />
                  <span>
                    {/* {labelDetails?.label_details[0]?.Search} */}
                    Search
                    </span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                as="li"
                className={
                  myBBTabSelected ? 'active miniScreens' : 'miniScreens'
                }
              >
                <Nav.Link onClick={() => handleHeaderTabs('ABOUT')}>
                  <img src={aboutActive} alt="About Us" />
                  <span>
                    {/* {labelDetails?.label_details[0]?.Aboutus} */}
                    About us
                  </span>
                </Nav.Link>

                
                {/* <Nav.Link onClick={dahiURL}>
                  <img src="https://mybb.etvbalbharat.com/kids_birthdates_2023/images/navi-bday.png" alt="Kids Birthday" title="Kids Birthday" width="90%" height="90%" />
                  <span>
                    Birthday Bash
                  </span>
                </Nav.Link> */}

              </Nav.Item>
              {/* <Nav.Item as="li" className="miniScreens">
                {(parseInt(yymmdd) >= 20230701 && parseInt(yymmdd) <= 20230731) ? (
                  <Nav.Link onClick={dahiURL}>
                    <img src="https://mybb.etvbalbharat.com/conan_contest/images/Conan-icon.png" width="100%" height="100%" alt="Conan Contest" title="Conan Contest" />
                    <span>
                      Conan Contest
                    </span>
                  </Nav.Link>
                ) : (<p></p>)}
              </Nav.Item> */}
              
              <Nav.Item as="li" className="miniScreens contest-li" >
                {(parseInt(yymmdd) >= 20241027 && parseInt(yymmdd) <= 20241114) ? (
                  <Nav.Link onClick={diwali_URL}>
                     <img src="https://mybb.etvbalbharat.com/rangoli/images/DIYA_GIF_SJ.gif" alt="Diwali" width="40px" title="Diwali" />
                     <span>Diwali Rangowali  </span>
                  </Nav.Link>
                ) : (
                  <Nav.Link onClick={dahiURL}>
                    <img src="https://mybb.etvbalbharat.com/kids_birthdates_2023/images/navi-bday.png" alt="Kids Birthday" width="38px" title="Kids Birthday" />
                    <div>
                      Birthday Bash
                    </div>
                  </Nav.Link>
                )}
              </Nav.Item>

                  {/* diwali sheddule NavBar logo desktop design  */}

                 {/* <Nav.Item as="li" className="miniScreens">
                {(parseInt(yymmdd) >= 20241027 && parseInt(yymmdd) <= 20241114) ? (
                  <Nav.Link onClick={diwali_URL}>
                     <img src="https://mybb.etvbalbharat.com/rangoli/images/DIYA_GIF_SJ.gif" alt="Diwali" width="40px" title="Diwali" />
                     <span>Diwali Rangowali  </span>
                  </Nav.Link>
                ) : (<p></p>)}
              </Nav.Item>  */}



       {/* Added By MC  03/07/2024  : 01:03 PM */}
        
      {/* <Nav.Item as="li"
        className={
          myBBTabSelected  ? 'active miniScreens' : 'miniScreens'
       }  >
    <Nav.Link onClick={() => handleHeaderTabs('Event')}>
      <img src={aboutActive} alt="EventsIcon" /> 
      <span> Event
          {labelDetails?.group_details?.[0]?.group_catg_name} 
      </span>
    </Nav.Link>
  </Nav.Item>  */}
{/* Close */}


            {/* <Nav.Item as="li" className="miniScreens"> */}
              {/* <Nav.Link onClick={handlequiz}>*/}
              {/* <Nav.Link onClick={handlequiz}> */}
              {/* <img src='https://mybb.etvbalbharat.com/The_Heritage_Quest/images/mcog-icon.png' width="80%" height="80%" alt="mcog_2022"  title="mcog_2022" /> */}
              {/*<img src='https://mybb.etvbalbharat.com/paintathon-2021/images/event-icon.png' width="80%" height="80%" alt="events_2022"  title="events_2022" />*/}
              {/*<img src='https://mybb.etvbalbharat.com/paintathon-2022/images/Childrensday-icon.png' width="60%" height="60%" alt="paintathon-2022"  title="paintathon-2022" />*/}
              {/* </Nav.Link> */}
              {/* </Nav.Item> */}
            </Nav>
          </Col>
          <Col className="col-2 d-flex align-items-center justify-content-center">
            {isLogin ? (
              <div className="Login-btn">
                <div
                  className="notification mr-3"
                  role="button"
                  tabIndex={0}
                  onClick={handleNotificationClick}
                  onKeyPress={handleNotificationClick}
                >
                  <img src={notificationBellWhite} alt="notificationBellIcon" />
                  <span>{totalNotifications}</span>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  onKeyPress={() => { }}
                  onClick={handleAvatarClick}
                >
                  <img
                    src={headerPic}
                    alt="Avatar Icon"
                    className="profile-pic"
                  />
                </div>
              </div>
            ) : (
              <Button
                className="btn-pink"
                tabIndex={0}
                onKeyPress={() => { }}
                onClick={handleLoginClick}
              >
                {t('login:login')}
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Row className="no-gutters">
        <Col className="col-4 responsiveLogoContent">
          <div
            className="logo-mobile d-block d-lg-none "
            aria-hidden="true"
            onClick={gotoLanguagePage}
          >
            <div className="logoBackground">
              <img
                src={dynamicLogo}
                alt="Etv"
                className="responsivelogo logoBackground"
              />
            </div>
          </div>
        </Col>
        {/* Mobile design Diwali Regarding Logo */}
        
        <Col className="col-4 responsiveLogoContent">
          <div
            className="birthdayicon"
            aria-hidden="true"
            onClick={handleClick} 
          >
                  <img src={imageSrc} alt={altText} />
                   <p>{iconName}</p>
          </div>
        </Col>
        <Col className="col-4 mobileTopSection">
          {isLogin ? (
            <div className="Login-btn">
              <div
                className="notification mr-3"
                role="button"
                tabIndex={0}
                onClick={handleNotificationClick}
                onKeyPress={handleNotificationClick}
              >
                <img src={notificationBellWhite} alt="notificationBellIcon" />
                <span className="notificationcount">{totalNotifications}</span>
              </div>
              <div
                role="button"
                tabIndex={0}
                onKeyPress={() => { }}
                onClick={handleAvatarClick}
              >
                <img
                  src={headerPic}
                  alt="Avatar Icon"
                  className="profile-pic dynamicUserPic"
                />
              </div>
            </div>
          ) : (
            <Button
              className="btn-pink onHeader"
              tabIndex={0}
              onKeyPress={() => { }}
              onClick={handleLoginClick}
            >
              {t('login:login')}
            </Button>
          )}
        </Col>
      </Row>
      <Row className="no-gutters">
        <Col className="bottomHeader">
          <Nav as="ul" role="menu" className="subMenu">

            {/* <Nav.Item as="li" className={eventTabSelected ? 'active' : ''}>
              <Nav.Link onClick={()=> handleHeaderTabs('EVENT')}>
                <img src={newHomePageEventIcon} alt="" />
                <span>   Event
                   {labelDetails?.label_details[0]?.Home} 
                   </span>
              </Nav.Link>
            </Nav.Item> */}
            
            <Nav.Item as="li" className={homeTabSelected ? 'active' : ''}>
              <Nav.Link onClick={handleLogoClick}>
                <img src={ResponsiveHomeIcon} alt="" />
                <span> {labelDetails?.label_details[0]?.Home}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className={tvShowsTabSelected ? 'active' : ''}>
              <Nav.Link onClick={() => handleHeaderTabs('SHOWS')}>
                <img src={ResponsiveTvIcon} alt="" />
                <span>{labelDetails?.group_details?.[0]?.group_catg_name}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className={moviesTabSelected ? 'active' : ''}>
              <Nav.Link onClick={() => handleHeaderTabs('MOVIES')}>
                <img src={ResponsiveMoveIcon} alt="" />
                <span>{labelDetails?.group_details?.[1]?.group_catg_name}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="">
              <Nav.Link onClick={handleSearch}>
                <img src={ResponsiveSearchIcon} alt="" />
                <span>{labelDetails?.label_details[0]?.Search}</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="aboutUs">
              <Nav.Link onClick={() => handleHeaderTabs('ABOUT')}>
                <img src={aboutInActive} alt="about- us" />
                <span>{labelDetails?.label_details[0]?.Aboutus}</span>
              </Nav.Link>
            </Nav.Item>

          </Nav>
          
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        className="coming_soon"
      >
        <Modal.Body style={{ backgroundColor: "#e2f2ff" }} >
          <div
            className="close-btn float-right"
            role="button"
            onClick={handleClose}
            tabIndex={0}
            onKeyPress={() => { }}
          >
            <img src={closeIcon} alt="close" />
          </div>
          <div className="about_player" style={{ textAlign: "center", marginLeft: "0px" }}  >
            {/*<a href={dahiURL} >*/}

            {(parseInt(yymmdd) >= 20230618 && parseInt(yymmdd) <= 20230630) ? (
              <img src="https://mybb.etvbalbharat.com/banners/ConanContestDryPost.jpg" width="100%" height="100%" alt="Detective Conan contest" title="Detective Conan contest" />
            ) : (
              (parseInt(yymmdd) >= 20230701 && parseInt(yymmdd) <= 20230731) ? (
                <a href='/' onClick={dahiURL} >
                  <img src="https://mybb.etvbalbharat.com/banners/popup-image.jpg" width="100%" height="100%" alt="JWC - Conan contest" title="JWC - Conan contest" />
                </a>
              ) : (
                (parseInt(yymmdd) >= 20230910 && parseInt(yymmdd) <= 20230913) ? (
                  <a href='/' onClick={dahiURL} >
                    <img src="https://mybb.etvbalbharat.com/banners/Emoticons-Pop-Up-Window.png" width="100%" height="100%" alt="JWC - Conan contest" title="JWC - Conan contest" />
                  </a>
                ) : (
                  (parseInt(yymmdd) >= 20240427 && parseInt(yymmdd) <= 20240530) ? (
                  <a href='/' onClick={dahiURL} >
                    <img src="https://mybb.etvbalbharat.com/banners/pop-up-final-24.png" width="100%" height="100%" alt="JWC - Conan contest" title="JWC - Conan contest" />
                  </a>
                  ) : (
                    <a href='/' onClick={dahiURL} >
                      <img src="https://cms.etvbalbharat.com/mybb/rangoli/images/pop-up.png" alt="Kids Birthday" title="Kids Birthday" width="100%" height="100%" />
                    </a>
                  )
                )
              )
            )}

            {/* {(parseInt(yymmdd) <= 20230426) ? (
              <img src="https://mybb.etvbalbharat.com/banners/GhibliStudio.png" alt="GhibliStudio" title="GhibliStudio" width="100%" height="100%" />
            ) : (
              <a onClick={dahiURL} >
                <img src="https://mybb.etvbalbharat.com/banners/homepagepopup.jpg" alt="Kids Birthday" title="Kids Birthday" width="100%" height="100%" />
              </a>
            )} */}

            {/* <a onClick={dahiURL} > */}
            {/*<img src='https://mybb.etvbalbharat.com/The_Heritage_Quest/images/mcog-icon.png' alt="mcog-2022"  title="mcog-2022" width="60%" height="20%" />*/}
            {/* {  (month === 11 && datenew < 14) ? ( */}
            {/* <img src="https://mybb.etvbalbharat.com/paintathon-2022/images/PaintathonPopUpNew.jpg" alt="paintathon-2022" title="paintathon-2022" width="100%" height="100%" /> */}
            {/* ) : ( */}
            {/* <img src="https://mybb.etvbalbharat.com/banners/GhibliStudio.png" alt="GhibliStudio" title="GhibliStudio" width="100%" height="100%" /> */}
            {/* <img src="https://mybb.etvbalbharat.com/banners/homepagepopup.jpg" alt="Kids Birthday" title="Kids Birthday" width="100%" height="100%" /> */}
            {/* )} */}
            {/*<img src='https://mybb.etvbalbharat.com/paintathon-2022/images/PaintathonPopup.jpg' alt="paintathon-2022"  title="paintathon-2022" width="100%" height="100%" />*/}
            {/* </a> */}
            {/*<br></br>
              <Button
                className="btn-pink"
                tabIndex={0}
                onKeyPress={() => {}}
                onClick={handlemcog}
              >
                {('Play now')}
              </Button>
               {/*<a onClick={handlemcog} >
              <img src='https://mybb.etvbalbharat.com/The_Heritage_Quest/images/PLAYNOW.png' alt="mcog-2022"  title="mcog-2022" width="40%" height="40%"  style={{marginLeft:"-88px"}} />
              </a>
              <a onClick={handleClose} >
              <img src='https://mybb.etvbalbharat.com/The_Heritage_Quest/images/QUIT.png' alt="mcog-2022"  title="mcog-2022" width="40%" height="40%" style={{marginRight:"-1px"}} />
              </a>*/}
          </div>
        </Modal.Body>
      </Modal>

      {showSearchModal && (
        <Search
          show={showSearchModal}
          onHide={() => setShowSearchModal(false)}
        />
      )}
    </div>
  );
};






































































































// //there will we update anythink changes requried .. there palce Header events logo and footer events Logo is working ...........03/12/2024...


// import React, { useEffect, useState } from 'react';
// import { Col, Modal, Row, Nav, Button } from 'react-bootstrap';
// import { useTranslation } from 'react-i18next';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';

// import {
//   avatarHeader,
//   HiImgNew,
//   newHomePageSearch,
//   notificationBellWhite,
//   newHomePageMovies,
//   newHomePageHTvShows,
//   ResponsiveHomeIcon,
//   ResponsiveMoveIcon,
//   ResponsiveTvIcon,
//   newHomePageEventIcon,
//   ResponsiveSearchIcon,
//   aboutActive,
//   aboutInActive,
//   defaultLogo,
// } from '../../constants/iconImageConstant';
// import { ROUTER_URL_CONSTANT } from '../../constants/routerUrlConstant';
// import { selectors as userStoreSelectors } from '../../store/userStore';
// import { Search } from '../search/Search';
// import { notificationsCount } from '../../services/notificationsCount/notificationsCount';
// import { selectors as notificationsCountSelectors } from '../../store/notificationsCount';
// import { selectors as lables } from '../../store/categoryMaster';
// import { selectLanguageSelector } from '../../store/selectLanguage';
// import { getItem } from '../../utils/storage';
// import { closeIcon} from '../../constants/iconImageConstant';


// export const Header: React.FC = () => {
//   const history = useHistory();
//   const dispatch = useDispatch();
//   const { t } = useTranslation(['header']);
//   const [dynamicLogo, setDynamicLogo] = useState(defaultLogo);
//   const [headerPic, setHeaderPic] = useState(avatarHeader);
//   const userLang = useSelector(selectLanguageSelector.getSelectLanguageState);
//   const [imageSrc, setImageSrc] = useState('');
//   const [iconName, setIconName] = useState('');
//   const [altText, setAltText] = useState('');



//   useEffect(() => {
//     if (userLang.lang_image !== '' && userLang.lang_image !== null) {
//       setDynamicLogo(userLang.lang_image);
//     }
//     // BUILD-1
//     // else {
//     //   setDynamicLogo(dynamicLogo);
//     // }
//   }, [userLang]);
//   const [homeTabSelected, sethomeTabSelected] = useState(false);
//   const [tvShowsTabSelected, setTvShowsTabSelected] = useState(false);
//   const [moviesTabSelected, setMoviesTabSelected] = useState(false);
//   const [myBBTabSelected, setMyBBTabSelected] = useState(false);
//   const [mySearchTabSelected, setSearchTabSelected] = useState(false);
//   const [myAboutTabSelected, setAboutTabSelected] = useState(false);
//   const [eventTabSelected, setEventTabSelected] = useState(false)
//   const [isLogin, setIslogin] = useState(false);
//   const [showSearchModal, setShowSearchModal] = useState(false);
//   const userStoreState = useSelector(userStoreSelectors.getUserStoreState);
//   const labelData = useSelector(lables.getCatagoryMasterState);
//   const labelDetails = labelData?.data;
//   // const [isMaxScroll, setIsMaxScroll] = useState(false);

//   const notificationsCountState = useSelector(
//     notificationsCountSelectors.getNotificationsCountState
//   );
//   const totalNotifications =
//     notificationsCountState.data && notificationsCountState.data[0].count;

//   // useEffect(() => {
//   //   if (typeof window !== 'undefined') {
//   //     window.onscroll = () => {
//   //       const currentScrollPos = window.pageYOffset;
//   //       const maxScroll = document.body.scrollHeight - window.innerHeight;
//   //       if (currentScrollPos === maxScroll) {
//   //         setIsMaxScroll(true);
//   //       } else {
//   //         setIsMaxScroll(false);
//   //       }
//   //     };
//   //   }
//   // }, []);

//   const useEffectOnMount = (effect: React.EffectCallback) => {
//     React.useEffect(effect, [userStoreState.data]);
//   };
//   useEffectOnMount(() => {
//     if (userStoreState?.data?.IsverifiedOTP === 'Y') {
//       if (getItem('selectedKid')) {
//         const kid = getItem('selectedKid');
//         setHeaderPic(kid?.avatarimages);
//       }
//       setIslogin(true);
//       const user = userStoreState.data.userid;

//       if (user) {
//         const params2 = {
//           user,
//           kidid: user.selectedKidid || '',
//           device_id: '',
//         };
//         dispatch(notificationsCount(params2));
//       }
//     }
//   });

//   const urlPathName = history.location.pathname;
//   useEffect(() => {
//     if (
//       urlPathName.includes(ROUTER_URL_CONSTANT.HOME) ||
//       urlPathName.includes(ROUTER_URL_CONSTANT.LANGUAGE_SELECTION)
//     ) {
//       sethomeTabSelected(true);
//     } else if (urlPathName.includes(ROUTER_URL_CONSTANT.TV_SHOWS)) {
//       setTvShowsTabSelected(true);
//     } else if (urlPathName.includes(ROUTER_URL_CONSTANT.MOVIES)) {
//       setMoviesTabSelected(true);
//     } else if (urlPathName.includes(ROUTER_URL_CONSTANT.MY_BB)) {
//       setMyBBTabSelected(true);
//     }else if (urlPathName.includes(ROUTER_URL_CONSTANT.HOME_SEARCH)) {
//     setSearchTabSelected(true);
//   } else if (urlPathName.includes(ROUTER_URL_CONSTANT.ABOUT_US)) {
//     setAboutTabSelected(true);
//   } else if (urlPathName.includes(ROUTER_URL_CONSTANT.EVENTS)){
//     setEventTabSelected(true);
//   }
//   }, [urlPathName]);

//   const handleHeaderTabs = (selectedTab : any) => {
//     if (selectedTab === 'HOME') {
//       history.push(ROUTER_URL_CONSTANT.HOME);
//     } else if (selectedTab === 'SHOWS') {
//       history.push(ROUTER_URL_CONSTANT.TV_SHOWS);
//     } else if (selectedTab === 'MOVIES') {
//       history.push(ROUTER_URL_CONSTANT.MOVIES);
//     }else if (selectedTab === 'MOVIES') {
//       history.push(ROUTER_URL_CONSTANT.HOME_SEARCH);
//     }else if (selectedTab === 'ABOUT') {
//       history.push(ROUTER_URL_CONSTANT.ABOUT_US);
//     } else if (selectedTab === 'Event'){
//       history.push(ROUTER_URL_CONSTANT.EVENTS)
  
//     }
//   };

//   const handleSearch = () => {
//     setShowSearchModal(true);
//   };

//   const handleLogoClick = () => {
//     history.push(ROUTER_URL_CONSTANT.HOME);
//   };

//   const handleLoginClick = () => {
//     history.push(ROUTER_URL_CONSTANT.LOGIN);
//   };

//   const handleNotificationClick = () => {
//     history.push(ROUTER_URL_CONSTANT.HOME_NOTIFICATIONS);
//   };
//   const handleAvatarClick = () => {
//     history.push(ROUTER_URL_CONSTANT.MENU);
//   };
//   const gotoLanguagePage = () => {
//     history.push(ROUTER_URL_CONSTANT.LANGUAGE_SELECTION);
//   };
//   const [show, setShow] = useState(false);
//   // const handleShow = () => setShow(true);
//   const handleClose = () => setShow(false);
  
//   const datehh = new Date();
//   //alert(datehh);
//   const month = (datehh.getMonth() + 1).toString().padStart(2, "0");
//   //const datenew='0' + datehh.getDate();
//   const datenew = datehh.getDate().toString().padStart(2, "0");
//   const yearnw = datehh.getFullYear();
//   const yymmdd = yearnw + '' + month + '' + datenew;
//   //alert(datenew);
//   // alert(yymmdd);
//   //Added By MC on 14-08-2021 11:45 for Dahi Handi 2021


  
// // diwali sheddule 27/10/2024

//   const handleClick = () => {
//     if (iconName === 'Diwali Rangowali') {
//       window.location.assign('https://mybb.etvbalbharat.com/rangoli/index.php');
//     } else if (iconName === 'Birthday Bash') {
//       window.location.assign('https://mybb.etvbalbharat.com/kids_birthdates_2023');
//     }
//   };

    
//   const diwali_URL = () => {
//     window.location.assign('https://mybb.etvbalbharat.com/rangoli/index.php');
//   }

//   const dahiURL = () => {
//     //alert(datenew+month+yearnw);
//     //if((datenew+month+yearnw)>='01102022'){
//     //if((yearnw+month+datenew)>='20221001'){
//     // if(month === 10 || month === 11){

//     //   if(month === 11 && datenew < 14){
//     // window.location.assign('https://mybb.etvbalbharat.com/paintathon-2022/validate.php?lang='+userLang.lang_id);
//     // }else{

//     // }
//     if (parseInt(yymmdd) >= 20230701 && parseInt(yymmdd) <= 20230731) {
//       window.location.assign('https://mybb.etvbalbharat.com/conan_contest');
//     // } else if (parseInt(yymmdd) >= 20240427 && parseInt(yymmdd) <= 20240530) {
//     } else if (parseInt(yymmdd) >= 20241027 && parseInt(yymmdd) <= 20241114) {
//       window.location.assign('https://mybb.etvbalbharat.com/rangoli/images/pop-up.png');
//     } else {
//       window.location.assign('https://mybb.etvbalbharat.com/kids_birthdates_2023');
//     }
//   }

// //desktop Logo logic

//  useEffect(() => {
//     const today = new Date();
//     const day = today.getDate();
//     const month = today.getMonth(); 

//    console.log(month);
//     if ((month === 9 && day >= 27) || (month === 10 && day <= 14)) {
//       setImageSrc('https://mybb.etvbalbharat.com/rangoli/images/DIYA_GIF_SJ.gif');
//       setIconName('Diwali Rangowali');
//       setAltText('Diwali - Rangowali Celebration');
       
//     } else {
//       setImageSrc('https://mybb.etvbalbharat.com/kids_birthdates_2023/images/navi-bday.png');
//       setIconName('Birthday Bash');
//       setAltText('Birthday celebration image');

//     }
//   }, []);

//   return (
//     <div className="header-container">
//       <div className="header-container-web home-contest">
//         <img src={HiImgNew} className="Hi-img" alt="Hi Icon" />
//         <Row>
//           <Col className="col-8 offset-2 text-center">
//             <Nav as="ul" role="menu">
//              {/* Added new code related to events page navbar on 14/08/2024 */}
//               <Nav.Item 
//                 as="li"
//                 className={
//                   eventTabSelected ? 'active miniScreens' : 'miniScreens'
//                 }
//               >
//                 <Nav.Link onClick={() => handleHeaderTabs('Event')}>
//                   <img src={newHomePageEventIcon} alt="EventsIcon" />
//                   <span>
//                    Event
                  
//                    {labelDetails?.label_details[0]?.EVENT}
//                    {/* {labelDetails?.group_details?.[0]?.group_catg_name} */}


//                   </span>
//                 </Nav.Link>
//               </Nav.Item> 
//               <Nav.Item
//                 as="li"
//                 className={
//                   tvShowsTabSelected ? 'active miniScreens' : 'miniScreens home-contest'
//                 }
//               >
//                 <Nav.Link onClick={() => handleHeaderTabs('SHOWS')}>
//                   <img src={newHomePageHTvShows} alt="TVShowsIcon" />
//                   <span>
//                     {labelDetails?.group_details?.[0]?.SHOWS} Shows
//                   </span>
//                 </Nav.Link>
//               </Nav.Item>
//               <Nav.Item
//                 as="li"
//                 className={
//                   moviesTabSelected ? 'active miniScreens' : 'miniScreens'
//                 }
//               >
//                 <Nav.Link onClick={() => handleHeaderTabs('MOVIES')}>
//                   <img src={newHomePageMovies} alt="MoviesIcon" />
//                   <span>
//                     {labelDetails?.group_details?.[1]?.MOVIES}
//                     Movies
//                   </span>
//                 </Nav.Link>
//               </Nav.Item>
//               <Nav.Item
//                 as="li"
//                 className={`logo ${homeTabSelected ? 'active miniScreens' : 'miniScreens home-tab'
//                   }`}
//               >
//                 <Nav.Link>
//                   <img
//                     className="webLogo"
//                     src={dynamicLogo}
//                     alt="AppLogo"
//                     onClick={handleLogoClick}
//                     aria-hidden="true"
//                   />
//                   <div
//                     aria-hidden="true"
//                     onClick={gotoLanguagePage}
//                     className="active-redirect-btn"
//                   >
//                     &nbsp;
//                   </div>
//                 </Nav.Link>
//               </Nav.Item>
//               <Nav.Item as="li" className={
//                  mySearchTabSelected? 'active miniScreens' : 'miniScreens'
//                 }>
//                 <Nav.Link onClick={handleSearch}>
//                   <img src={newHomePageSearch} alt="SearchIcon" />
//                   <span>
//                     {/* {labelDetails?.label_details[0]?.Search} */}
//                     Search
//                     </span>
//                 </Nav.Link>
//               </Nav.Item>
//               <Nav.Item
//                 as="li"
//                 className={
//                   myAboutTabSelected ? 'active miniScreens' : 'miniScreens'
//                 }
//               >
//                 <Nav.Link onClick={() => handleHeaderTabs('ABOUT')}>
//                   <img src={aboutActive} alt="About Us" />
//                   <span>
//                     {/* {labelDetails?.label_details[0]?.Aboutus} */}
//                     About us
//                   </span>
//                 </Nav.Link>

                
//                 {/* <Nav.Link onClick={dahiURL}>
//                   <img src="https://mybb.etvbalbharat.com/kids_birthdates_2023/images/navi-bday.png" alt="Kids Birthday" title="Kids Birthday" width="90%" height="90%" />
//                   <span>
//                     Birthday Bash
//                   </span>
//                 </Nav.Link> */}

//               </Nav.Item>
//               {/* <Nav.Item as="li" className="miniScreens">
//                 {(parseInt(yymmdd) >= 20230701 && parseInt(yymmdd) <= 20230731) ? (
//                   <Nav.Link onClick={dahiURL}>
//                     <img src="https://mybb.etvbalbharat.com/conan_contest/images/Conan-icon.png" width="100%" height="100%" alt="Conan Contest" title="Conan Contest" />
//                     <span>
//                       Conan Contest
//                     </span>
//                   </Nav.Link>
//                 ) : (<p></p>)}
//               </Nav.Item> */}
              
//               <Nav.Item as="li" className="miniScreens contest-li" >
//                 {(parseInt(yymmdd) >= 20241027 && parseInt(yymmdd) <= 20241114) ? (
//                   <Nav.Link onClick={diwali_URL}>
//                      <img src="https://mybb.etvbalbharat.com/rangoli/images/DIYA_GIF_SJ.gif" alt="Diwali" width="40px" title="Diwali" />
//                      <span>Diwali Rangowali  </span>
//                   </Nav.Link>
//                 ) : (
//                   <Nav.Link onClick={dahiURL}>
//                     <img src="https://mybb.etvbalbharat.com/kids_birthdates_2023/images/navi-bday.png" alt="Kids Birthday" width="38px" title="Kids Birthday" />
//                     <div>
//                       Birthday Bash
//                     </div>
//                   </Nav.Link>
//                 )}
//               </Nav.Item>

//                   {/* diwali sheddule NavBar logo desktop design  */}

//                  {/* <Nav.Item as="li" className="miniScreens">
//                 {(parseInt(yymmdd) >= 20241027 && parseInt(yymmdd) <= 20241114) ? (
//                   <Nav.Link onClick={diwali_URL}>
//                      <img src="https://mybb.etvbalbharat.com/rangoli/images/DIYA_GIF_SJ.gif" alt="Diwali" width="40px" title="Diwali" />
//                      <span>Diwali Rangowali  </span>
//                   </Nav.Link>
//                 ) : (<p></p>)}
//               </Nav.Item>  */}



//        {/* Added By MC  03/07/2024  : 01:03 PM */}
        
//       {/* <Nav.Item as="li"
//         className={
//           myBBTabSelected  ? 'active miniScreens' : 'miniScreens'
//        }  >
//     <Nav.Link onClick={() => handleHeaderTabs('Event')}>
//       <img src={aboutActive} alt="EventsIcon" /> 
//       <span> Event
//           {labelDetails?.group_details?.[0]?.group_catg_name} 
//       </span>
//     </Nav.Link>
//   </Nav.Item>  */}
// {/* Close */}


//             {/* <Nav.Item as="li" className="miniScreens"> */}
//               {/* <Nav.Link onClick={handlequiz}>*/}
//               {/* <Nav.Link onClick={handlequiz}> */}
//               {/* <img src='https://mybb.etvbalbharat.com/The_Heritage_Quest/images/mcog-icon.png' width="80%" height="80%" alt="mcog_2022"  title="mcog_2022" /> */}
//               {/*<img src='https://mybb.etvbalbharat.com/paintathon-2021/images/event-icon.png' width="80%" height="80%" alt="events_2022"  title="events_2022" />*/}
//               {/*<img src='https://mybb.etvbalbharat.com/paintathon-2022/images/Childrensday-icon.png' width="60%" height="60%" alt="paintathon-2022"  title="paintathon-2022" />*/}
//               {/* </Nav.Link> */}
//               {/* </Nav.Item> */}
//             </Nav>
//           </Col>
//           <Col className="col-2 d-flex align-items-center justify-content-center">
//             {isLogin ? (
//               <div className="Login-btn">
//                 <div
//                   className="notification mr-3"
//                   role="button"
//                   tabIndex={0}
//                   onClick={handleNotificationClick}
//                   onKeyPress={handleNotificationClick}
//                 >
//                   <img src={notificationBellWhite} alt="notificationBellIcon" />
//                   <span>{totalNotifications}</span>
//                 </div>
//                 <div
//                   role="button"
//                   tabIndex={0}
//                   onKeyPress={() => { }}
//                   onClick={handleAvatarClick}
//                 >
//                   <img
//                     src={headerPic}
//                     alt="Avatar Icon"
//                     className="profile-pic"
//                   />
//                 </div>
//               </div>
//             ) : (
//               <Button
//                 className="btn-pink"
//                 tabIndex={0}
//                 onKeyPress={() => { }}
//                 onClick={handleLoginClick}
//               >
//                 {t('login:login')}
//               </Button>
//             )}
//           </Col>
//         </Row>
//       </div>
//       <Row className="no-gutters">
//         <Col className="col-4 responsiveLogoContent">
//           <div
//             className="logo-mobile d-block d-lg-none "
//             aria-hidden="true"
//             onClick={gotoLanguagePage}
//           >
//             <div className="logoBackground">
//               <img
//                 src={dynamicLogo}
//                 alt="Etv"
//                 className="responsivelogo logoBackground"
//               />
//             </div>
//           </div>
//         </Col>
//         {/* Mobile design Diwali Regarding Logo */}
        
//         <Col className="col-4 responsiveLogoContent">
//           <div
//             className="birthdayicon"
//             aria-hidden="true"
//             onClick={handleClick} 
//           >
//                   <img src={imageSrc} alt={altText} />
//                    <p>{iconName}</p>
//           </div>
//         </Col>
//         <Col className="col-4 mobileTopSection">
//           {isLogin ? (
//             <div className="Login-btn">
//               <div
//                 className="notification mr-3"
//                 role="button"
//                 tabIndex={0}
//                 onClick={handleNotificationClick}
//                 onKeyPress={handleNotificationClick}
//               >
//                 <img src={notificationBellWhite} alt="notificationBellIcon" />
//                 <span className="notificationcount">{totalNotifications}</span>
//               </div>
//               <div
//                 role="button"
//                 tabIndex={0}
//                 onKeyPress={() => { }}
//                 onClick={handleAvatarClick}
//               >
//                 <img
//                   src={headerPic}
//                   alt="Avatar Icon"
//                   className="profile-pic dynamicUserPic"
//                 />
//               </div>
//             </div>
//           ) : (
//             <Button
//               className="btn-pink onHeader"
//               tabIndex={0}
//               onKeyPress={() => { }}
//               onClick={handleLoginClick}
//             >
//               {t('login:login')}
//             </Button>
//           )}
//         </Col>
//       </Row>
//       <Row className="no-gutters">
//         <Col className="bottomHeader">
//           <Nav as="ul" role="menu" className="subMenu">

//             <Nav.Item as="li" className={eventTabSelected ? 'active' : ''}>
//               <Nav.Link onClick={()=> handleHeaderTabs('Event')}>
//                 <img src={newHomePageEventIcon} alt="" />
//                 <span>   Event
//                    {labelDetails?.label_details[0]?.EVENTS} 
//                    </span>
//               </Nav.Link>
//             </Nav.Item> 
            
//             <Nav.Item as="li" className={homeTabSelected ? 'active' : ''}>
//               <Nav.Link onClick={handleLogoClick}>
//                 <img src={ResponsiveHomeIcon} alt="" />
//                 <span>
//                    {/* {labelDetails?.label_details[0]?.Home} */}
//                    Home</span>
               
//               </Nav.Link>
//             </Nav.Item>
//             <Nav.Item as="li" className={tvShowsTabSelected ? 'active' : ''}>
//               <Nav.Link onClick={() => handleHeaderTabs('SHOWS')}>
//                 <img src={ResponsiveTvIcon} alt="" />
//                 <span> Shows
//                   {/* {labelDetails?.group_details?.[0]?.group_catg_name} */}
//                   </span>
//               </Nav.Link>
//             </Nav.Item>
//             <Nav.Item as="li" className={moviesTabSelected ? 'active' : ''}>
//               <Nav.Link onClick={() => handleHeaderTabs('MOVIES')}>
//                 <img src={ResponsiveMoveIcon} alt="" />
//                 <span> Movies
//                   {/* {labelDetails?.group_details?.[1]?.group_catg_name} */}

//                 </span>
//               </Nav.Link>
//             </Nav.Item>
//             <Nav.Item as="li" className="">
//               <Nav.Link onClick={handleSearch}>
//                 <img src={ResponsiveSearchIcon} alt="" />
//                 <span>Search
//                   {/* {labelDetails?.label_details[0]?.Search} */}

//                 </span>
//               </Nav.Link>
//             </Nav.Item>
//             <Nav.Item as="li" className="aboutUs">
//               <Nav.Link onClick={() => handleHeaderTabs('ABOUT')}>
//                 <img src={aboutInActive} alt="about- us" />
//                 <span>{labelDetails?.label_details[0]?.Aboutus}</span>
//               </Nav.Link>
//             </Nav.Item>

//           </Nav>
          
//         </Col>
//       </Row>
//       <Modal
//         show={show}
//         onHide={handleClose}
//         animation={false}
//         centered
//         className="coming_soon"
//       >
//         <Modal.Body style={{ backgroundColor: "#e2f2ff" }} >
//           <div
//             className="close-btn float-right"
//             role="button"
//             onClick={handleClose}
//             tabIndex={0}
//             onKeyPress={() => { }}
//           >
//             <img src={closeIcon} alt="close" />
//           </div>
//           <div className="about_player" style={{ textAlign: "center", marginLeft: "0px" }}  >
//             {/*<a href={dahiURL} >*/}

//             {(parseInt(yymmdd) >= 20230618 && parseInt(yymmdd) <= 20230630) ? (
//               <img src="https://mybb.etvbalbharat.com/banners/ConanContestDryPost.jpg" width="100%" height="100%" alt="Detective Conan contest" title="Detective Conan contest" />
//             ) : (
//               (parseInt(yymmdd) >= 20230701 && parseInt(yymmdd) <= 20230731) ? (
//                 <a href='/' onClick={dahiURL} >
//                   <img src="https://mybb.etvbalbharat.com/banners/popup-image.jpg" width="100%" height="100%" alt="JWC - Conan contest" title="JWC - Conan contest" />
//                 </a>
//               ) : (
//                 (parseInt(yymmdd) >= 20230910 && parseInt(yymmdd) <= 20230913) ? (
//                   <a href='/' onClick={dahiURL} >
//                     <img src="https://mybb.etvbalbharat.com/banners/Emoticons-Pop-Up-Window.png" width="100%" height="100%" alt="JWC - Conan contest" title="JWC - Conan contest" />
//                   </a>
//                 ) : (
//                   (parseInt(yymmdd) >= 20240427 && parseInt(yymmdd) <= 20240530) ? (
//                   <a href='/' onClick={dahiURL} >
//                     <img src="https://mybb.etvbalbharat.com/banners/pop-up-final-24.png" width="100%" height="100%" alt="JWC - Conan contest" title="JWC - Conan contest" />
//                   </a>
//                   ) : (
//                     <a href='/' onClick={dahiURL} >
//                       <img src="https://cms.etvbalbharat.com/mybb/rangoli/images/pop-up.png" alt="Kids Birthday" title="Kids Birthday" width="100%" height="100%" />
//                     </a>
//                   )
//                 )
//               )
//             )}

//             {/* {(parseInt(yymmdd) <= 20230426) ? (
//               <img src="https://mybb.etvbalbharat.com/banners/GhibliStudio.png" alt="GhibliStudio" title="GhibliStudio" width="100%" height="100%" />
//             ) : (
//               <a onClick={dahiURL} >
//                 <img src="https://mybb.etvbalbharat.com/banners/homepagepopup.jpg" alt="Kids Birthday" title="Kids Birthday" width="100%" height="100%" />
//               </a>
//             )} */}

//             {/* <a onClick={dahiURL} > */}
//             {/*<img src='https://mybb.etvbalbharat.com/The_Heritage_Quest/images/mcog-icon.png' alt="mcog-2022"  title="mcog-2022" width="60%" height="20%" />*/}
//             {/* {  (month === 11 && datenew < 14) ? ( */}
//             {/* <img src="https://mybb.etvbalbharat.com/paintathon-2022/images/PaintathonPopUpNew.jpg" alt="paintathon-2022" title="paintathon-2022" width="100%" height="100%" /> */}
//             {/* ) : ( */}
//             {/* <img src="https://mybb.etvbalbharat.com/banners/GhibliStudio.png" alt="GhibliStudio" title="GhibliStudio" width="100%" height="100%" /> */}
//             {/* <img src="https://mybb.etvbalbharat.com/banners/homepagepopup.jpg" alt="Kids Birthday" title="Kids Birthday" width="100%" height="100%" /> */}
//             {/* )} */}
//             {/*<img src='https://mybb.etvbalbharat.com/paintathon-2022/images/PaintathonPopup.jpg' alt="paintathon-2022"  title="paintathon-2022" width="100%" height="100%" />*/}
//             {/* </a> */}
//             {/*<br></br>
//               <Button
//                 className="btn-pink"
//                 tabIndex={0}
//                 onKeyPress={() => {}}
//                 onClick={handlemcog}
//               >
//                 {('Play now')}
//               </Button>
//                {/*<a onClick={handlemcog} >
//               <img src='https://mybb.etvbalbharat.com/The_Heritage_Quest/images/PLAYNOW.png' alt="mcog-2022"  title="mcog-2022" width="40%" height="40%"  style={{marginLeft:"-88px"}} />
//               </a>
//               <a onClick={handleClose} >
//               <img src='https://mybb.etvbalbharat.com/The_Heritage_Quest/images/QUIT.png' alt="mcog-2022"  title="mcog-2022" width="40%" height="40%" style={{marginRight:"-1px"}} />
//               </a>*/}
//           </div>
//         </Modal.Body>
//       </Modal>

//       {showSearchModal && (
//         <Search
//           show={showSearchModal}
//           onHide={() => setShowSearchModal(false)}
//         />
//       )}
//     </div>
//   );
// };

//  please do not changes any think events page  
























