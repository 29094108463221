import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_URL_CONSTANT } from '../../../constants/routerUrlConstant';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';
import { mob_conan_bg, conanbtn,volumeIcon,
muteIcon, } from '../../../constants/iconImageConstant';

export function ConanYearMobile() {
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  const handleMuteToggle = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <>
      <Header />
      <audio id="myAudio" autoPlay ref={audioRef}>
        <source
          src="https://assets.etvbalbharat.com/video/DCmusic.mp3"
          type="audio/mp3"
        />
      </audio>

      <div className="container-fluid mob-conanbg">
        <div className="col-md-12">
          <div className="mob-pain-nxtpg-cont">
            <img src={mob_conan_bg} alt="conan" />
            <div className="mob-conan-nxtgb">
              <Link to={ROUTER_URL_CONSTANT.MOB_CANON_WINNER_LIST}>
                <img src={conanbtn} alt="conan button" />
              </Link>
            </div>
          </div>
        </div>
        <button
                              className="mob-audio"
                              id="muteButton"
                              onClick={handleMuteToggle}
                            >
                              <img
                                id="muteIcon"
                                src={isMuted ? muteIcon : volumeIcon}
                                alt="Mute/Unmute Icon"
                              />
                            </button>
        <div className="clearfix"></div>
      </div>
      <Footer />
    </>
  );
}
