import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL_CONSTANTS } from '../../../constants/apiUrlConstants';
import { mob_winnerboard } from '../../../constants/iconImageConstant';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';

interface ImageDetail {
  id: string;
  name: string;
  image: string;
  age: string;
  drawing: string;
}

export function Mob_ArtistGallery_2022() {
  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;
  const [images, setImages] = useState<ImageDetail[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState<ImageDetail | null>(null);

  useEffect(() => {
    fetchArtistGalleryImages('2022');
  }, []);

  const fetchArtistGalleryImages = async (year: string) => {
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const responseData_A = res.data?.A || [];
      const responseData_B = res.data?.B || []; 
      // added there slice method remove group A,B first 3 images 11/12/2024
   
      const groupA = responseData_A.filter((item: ImageDetail) => item.age.includes('Group A')).slice(3);
      const groupB = responseData_B.filter((item: ImageDetail) => item.age.includes('Group B')).slice(3);

    const combinedData = [...groupA, ...groupB];
    setImages(combinedData);

    } catch (error) {
      // console.log('Error fetching artist gallery images:', error);
    }
  };

  const handleImageClick = (drawing: ImageDetail) => {
    setSelectedDrawing(drawing);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDrawing(null);
  };

  return (
    <>
      <Header />
      <div className="container-fluid mob-pain-winlist">
        <div className="col-md-12">
          <div className="mob-pain-yrs21">
            <div className="yer-2021">
              <img src={mob_winnerboard} alt="paintathon-2022" width="100%" />
            </div>

            <div className="pain-winlist-box24">
              <div className="col-md-12 mb-3">
                <span className="winlis-tit">Artist Gallery</span>
              </div>

              <div className="col-md-12 artist-main">
                <div className="mega-win-container mb-2">
                  <ul>
                    {images.map((item) => (
                      <li key={item.id} className="artbox">
                        <a href="#" onClick={() => handleImageClick(item)} data-toggle="modal">
                          <img
                            src={`https://assets.etvbalbharat.com/${item.image}`}
                            alt={item.name}
                            width="100%"
                          />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && selectedDrawing && (
        <div className="modal fade show"  style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" onClick={closeModal}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content new-body">
              <div className="modal-header new-mod-head">
                <button type="button" className="close close-btn new-close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <img src={`https://assets.etvbalbharat.com/${selectedDrawing.drawing}`} width="100%" alt="Drawing" />
                  <div className="drw-cand-name">{selectedDrawing.name}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}












































