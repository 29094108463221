// new design code 05/12/2024....................04:43PM;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL_CONSTANTS } from '../../constants/apiUrlConstants';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';
import { paintbrush } from '../../constants/iconImageConstant';

interface SessionDetail {
  id: string;
  name: string;
  image: string;
  drawing: string;
  age: string;
  position: string;
}

export function PaintathonWinnerList() {
  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;

  const [selectedYear, setSelectedYear] = useState('2021');
  const [responseA, setResponseA] = useState<SessionDetail[]>([]);
  const [responseB, setResponseB] = useState<SessionDetail[]>([]);
  const [selectedDrawing, setSelectedDrawing] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchPaintathonResult(selectedYear);
  }, [selectedYear]);

  const fetchPaintathonResult = async (year: string) => {
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const data = res.data;

      // Filter and set data for Group A and Group B based on the data returned
      setResponseA(data.A || []);
      setResponseB(data.B || []);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  const handleYearChange = (year: string) => {
    setSelectedYear(year);
  };

  const handleImageClick = (drawing: string) => {
    setSelectedDrawing(drawing);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDrawing(null);
  };

  const ImageGroup = ({
    title,
    group,
  }: {
    title: string;
    group: SessionDetail[];
  }) => {
    // Sort the group based on position
    const sortedGroup = group.sort(
      (a, b) => parseInt(a.position) - parseInt(b.position)
    );
    return (
      <>
        <h3 className="mt-2 mb-2 mt-3">{title}</h3>
        <div className="col-md-6 offset-md-3 col-sm-6 offset-sm-3 mb-3 nopd">
          <div className="row">
            {sortedGroup.map((item) => (
              <div className="col-md-4 col-sm-4" key={item.id}>
                <div
                  className="gropbox"
                  onClick={() => handleImageClick(item.drawing)}
                >
                  <img
                    src={`https://assets.etvbalbharat.com/${item.image}`}
                    alt={item.name}
                    width="100%"
                  />
                </div>
                <div className="winner-tit">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Header />
      <div className="container-fluid page-bgs">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="pain-win-yrs">
                <div className="win-list-box">
                  <div className="pain-yrs zoombt yrs21 ">
                    <Link
                      to="events/paintathon-drawing-competition-2021"
                      onClick={() => handleYearChange('2021')}
                    >
                      2021
                    </Link>
                  </div>
                  <div className="pain-yrs zoombt">
                    <Link
                      to="paintathon-drawing-competition-2022"
                      onClick={() => handleYearChange('2022')}
                    >
                      2022
                    </Link>
                  </div>
                  <div className="pain-yrs zoombt">
                    <Link
                      to="paintathon-drawing-competition-2024"
                      onClick={() => handleYearChange('2024')}
                    >
                      2024
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="pain-win-list-21">
                <div className="mega-win-1st list-22">
                  <div className="win-tit">Winner's List</div>
                  {responseA.length > 0 && (
                    <ImageGroup title="Group A" group={responseA.slice(0, 3)} />
                  )}
                  {responseB.length > 0 && (
                    <ImageGroup title="Group B" group={responseB.slice(0, 3)} />
                  )}
                  <div className="col-md-12">
                    <span className="art-gal">
                      <Link to="paintathon-artist-gallerylist-2021">
                        Artist Gallery
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <div className="paintbrush-bk-main">
                <div className="paintboxmain">
                  <img src={paintbrush} alt="paintbrush" />
                </div>
                <div className="bk-btn zoombt">
                  <a href="/events">Back to Main Menu</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div
          className="modal fade show"
          style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          onClick={closeModal}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content paintathon-modal-bg">
              <div className="modal-header modal-he-adj">
                <button
                  type="button"
                  className="close close-bg"
                  onClick={closeModal}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body nopd">
                <div className="drws">
                  {selectedDrawing && (
                    <>
                      <img
                        src={`https://assets.etvbalbharat.com/${selectedDrawing}`}
                        width="100%"
                        alt="Drawing"
                      />
                      <div className="drw-cand-name">
                        {
                          responseA
                            .concat(responseB)
                            .find((item) => item.drawing === selectedDrawing)
                            ?.name
                        }
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
}

















































































































