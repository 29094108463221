import React, { useEffect, useState } from 'react';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';
import { fetchWinners, Winner } from '../../services/conanContest/conanService';
import { conanwin } from '../../constants/iconImageConstant';

export function CanonWinList() {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedWinner, setSelectedWinner] = useState<Winner | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const getWinners = async () => {
      try {
        const data = await fetchWinners();
        setWinners(data);
      } catch (err) {
        setError('Failed to fetch winners');
      } finally {
        setLoading(false);
      }
    };

    getWinners();
  }, []);

  const getGroupLabel = (ageGroup: string) => {
    switch (ageGroup) {
      case 'GROUP A(0-10)':
        return 'Group - A';
      case 'GROUP B(11-15)':
        return 'Group - B';
      case 'GROUP C(16-20)':
        return 'Group - C';
      default:
        return '';
    }
  };

  const handleWinnerClick = (winner: Winner) => {
    setSelectedWinner(winner);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedWinner(null);
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="conan-bgs">
            <img src={conanwin} alt="Conan Win" width="100%" />
          </div>
          <div className="col-md-6 offset-md-3">
            <div className="conan-winlistbg text-center">
              {loading ? (
                <div>Loading...</div>
              ) : error ? (
                <div>{error}</div>
              ) : winners.length > 0 ? (
                <>
                  {/* Group A Winner */}
                  <div className="col-md-6 offset-md-3 col-sm-6 offset-sm-3 mb-1">
                    <div className="gp01">
                      <h3>{getGroupLabel(winners[0].age)}</h3>
                      <div className="win-name">{winners[0].name}</div>
                      <div className="win-pic">
                        <a
                          href="#"
                          onClick={(e) =>{e.preventDefault(); handleWinnerClick(winners[0])}}
                           >
                          <img
                            src={`https://assets.etvbalbharat.com/conan_contest/${winners[0].photo}`}
                            alt="Winner"
                            className="img-res"
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* Other Winners */}
                  <div className="col-md-12 col-sm-12 mt-2">
                    <div className="row">
                      {winners.slice(1).map((winner) => (
                        <div
                          key={winner.id}
                          className="col-md-6 col-sm-6 text-center"
                        >
                          {' '}
                          {/* Added text-center class */}
                          <div className="grsbc">
                            <h3>{getGroupLabel(winner.age)}</h3>
                            <div className="win-name">{winner.name}</div>
                            <div className="win-pic">
                              <a
                                href="#"
                                onClick={(e) =>{e.preventDefault(); handleWinnerClick(winner)}}
                              >
                                <img
                                  src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
                                  alt="Winner"
                                  className="img-res"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <div>No winners available.</div>
              )}
            </div>
          </div>
        </div>

        {showModal && (
          <div
            className="modal fade show"
            style={{ display: 'block' }}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
            onClick={closeModal}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content modal-bg">
                <div className="modal-header modal-he-adj">
                  <button
                    type="button"
                    className="close close-bg"
                    onClick={closeModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body nopd">
                  <div className="drws">
                    {selectedWinner && (
                      <>
                        <div className="embed-responsive embed-responsive-4by3">
                          <video
                            width="100%"
                            height="auto"
                            controls
                            src={`https://assets.etvbalbharat.com/conan_contest/${selectedWinner.video}`}
                          >
                            Your browser does not support the video tag.
                          </video>
                        </div>
                        <div className="drw-cand-name">
                          {selectedWinner.name}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
