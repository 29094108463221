import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL_CONSTANTS } from '../../constants/apiUrlConstants';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';
import { paintbrush } from '../../constants/iconImageConstant';

interface SessionDetail {
  id: string;
  name: string;
  image: string;
  age: string;
  position: string;
  drawing: string;
  MEGA: string;
}

export function PainwinList_2024() {
  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;

  const [selectedYear, setSelectedYear] = useState('2024');
  const [response, setResponse] = useState<{ groupA: SessionDetail[]; groupB: SessionDetail[]; groupC: SessionDetail[] }>({
    groupA: [],
    groupB: [],
    groupC: [],
  });
  const [selectedDrawing, setSelectedDrawing] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [megaImage, setMegaImage] = useState<SessionDetail | null>(null);

  useEffect(() => {
    fetchPaintathonResult(selectedYear);
  }, [selectedYear]);

  const fetchPaintathonResult = async (year: string) => {
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );

      const responseData_A = res.data?.A || [];
      const responseData_B = res.data?.B || [];
      const responseData_C = res.data?.C || [];
      
      const groupA = responseData_A
        .filter((item: SessionDetail) => item.age.includes('Group A'))
        // .sort((a, b) => a.position.localeCompare(b.position)); 
      
      const groupB = responseData_B
        .filter((item: SessionDetail) => item.age.includes('Group B'))
        // .sort((a, b) => a.position.localeCompare(b.position));

      const groupC = responseData_C
        .filter((item: SessionDetail) => item.age.includes('Group C'))
        // .sort((a, b) => a.position.localeCompare(b.position)); // Sort by position

      const megaWinner = res.data?.MEGA || null;

      setMegaImage(megaWinner);
      setResponse({
        groupA,
        groupB,
        groupC,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleYearChange = (year: string) => {
    setSelectedYear(year);
  };

  const handleImageClick = (drawing: string) => {
    setSelectedDrawing(drawing);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedDrawing(null);
  };

  const ImageGroup = ({ title, group }: { title: string; group: SessionDetail[] }) => (
    <>
      <h3 className="mt-2 mb-2">{title}</h3>
      <div className="col-md-6 offset-md-3 nopd">
        <div className="row">
          {group.slice(0, 3).map(item => (
            <div className="col-md-4 col-sm-4" key={item.id}>
              <div className="gropbox" onClick={() => handleImageClick(item.drawing)}>
                <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} width="100%" />
              </div>
              <div className="winner-tit">{item.name}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div className="container-fluid page-bgs">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="pain-win-yrs">
                <div className="win-list-box">
                  <div className="pain-yrs zoombt">
                    <Link to="paintathon-drawing-competition-2021" onClick={() => handleYearChange('2021')}>2021</Link>
                  </div>
                  <div className="pain-yrs zoombt">
                    <Link to="paintathon-drawing-competition-2022" onClick={() => handleYearChange('2022')}>2022</Link>
                  </div>
                  <div className="pain-yrs zoombt yrs24">
                    <Link to="#" onClick={() => handleYearChange('2024')}>2024</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="pain-win-list">
                <div className="mega-win-1st">
                  {megaImage && (
                    <div onClick={() => handleImageClick(megaImage.drawing)} style={{ cursor: 'pointer' }}>
                      <div className="win-tit">Winner's List</div>
                      <h3 className="mt-3">Mega Winner</h3>
                      <div className="megawin">
                        <img src={`https://assets.etvbalbharat.com/${megaImage.image}`} alt={megaImage.name} width="100%" />
                      </div>
                      <div className="winner-tit">{megaImage.name}</div>
                    </div>
                  )}

                  <ImageGroup title="Group A" group={response.groupA} />
                  <ImageGroup title="Group B" group={response.groupB} />
                  <ImageGroup title="Group C" group={response.groupC} />
                  <div className="col-md-12">
                    <span className="art-gal">
                      <Link to="paintathon-artist-gallerylist-2024">Artist Gallery</Link>
                    </span>
                  </div>
                </div>
              </div>
              <div className="paintbrush-bk-main">
                <div className="paintboxmain">
                  <img src={paintbrush} alt="paintbrush" />
                </div>
                <div className="bk-btn zoombt">
                  <a href="/events">Back to Main Menu</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for displaying the drawing */}
      {showModal && (
        <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" onClick={closeModal}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content paintathon-modal-bg">
              <div className="modal-header modal-he-adj">
                <button type="button" className="close close-bg" onClick={closeModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body nopd">
                <div className="drws">
                  {selectedDrawing && (
                    <>
                      <img src={`https://assets.etvbalbharat.com/${selectedDrawing}`} width="100%" alt="Drawing" />
                      <div className="drw-cand-name">
                        {selectedDrawing === megaImage?.drawing
                          ? megaImage?.name
                          : [
                            ...response.groupA,
                            ...response.groupB,
                            ...response.groupC,
                          ].find((item) => item.drawing === selectedDrawing)?.name}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}


















// added new code with artiest Gallary  09/12/2024....

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';
// import { API_URL_CONSTANTS } from '../../constants/apiUrlConstants';
// import { Header } from '../Header/Header';
// import { Footer } from '../appFooter/Footer';
// import { paintbrush } from '../../constants/iconImageConstant';

// interface SessionDetail {
//   id: string;
//   name: string;
//   image: string;
//   age: string;
//   position: string;
//   drawing: string;
//   MEGA: string;
// }

// export function PainwinList_2024() {
//   const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;
  
//   const [selectedYear, setSelectedYear] = useState('2024');
//   const [response, setResponse] = useState<{ groupA: SessionDetail[]; groupB: SessionDetail[]; groupC: SessionDetail[] }>({
//     groupA: [],
//     groupB: [],
//     groupC: [],
//   });
//   const [selectedDrawing, setSelectedDrawing] = useState<string | null>(null);
//   const [showModal, setShowModal] = useState(false);
//   const [megaImage, setMegaImage] = useState<SessionDetail | null>(null);
  

//   useEffect(() => {
//     fetchPaintathonResult(selectedYear);
//   }, [selectedYear]);

//   const fetchPaintathonResult = async (year: string) => {
//     try {
//       const res = await axios.post(
//         url,
//         { params: { year } },
//         { headers: { 'Content-Type': 'application/json' } }
//       );
//        const responseData_A = res.data?.A || [];
//        const responseData_B = res.data?.B || [];
//        const responseData_C = res.data?.C || [];
     
//       const groupA = responseData_A.filter((item: SessionDetail) => item.age.includes('Group A')).sort((a, b) => a.position.localeCompare(b.position));
//       const groupB = responseData_B.filter((item: SessionDetail) => item.age.includes('Group B')).sort((a, b) => a.position.localeCompare(b.position));
//       const groupC = responseData_C.filter((item: SessionDetail) => item.age.includes('Group C')).sort((a, b) => a.position.localeCompare(b.position));

//       const megaWinner = res.data?.MEGA || null;

//       setMegaImage(megaWinner);
//       setResponse({
//         groupA,
//         groupB,
//         groupC,
//       });
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };


//   const handleYearChange = (year: string) => {
//     setSelectedYear(year);
//   };

//   const handleImageClick = (drawing: string) => {
//     setSelectedDrawing(drawing);
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setSelectedDrawing(null);
//   };
  
//   const ImageGroup = ({ title, group }: { title: string; group: SessionDetail[] }) => (
//     <>
//       <h3 className="mt-2 mb-2">{title}</h3>
//       <div className="col-md-6 offset-md-3  nopd">
//         <div className="row">
//         {group.slice(0, 3).map(item => (
//             <div className="col-md-4 col-sm-4" key={item.id}>
//               <div className="gropbox" onClick={() => handleImageClick(item.drawing)}>
//                 <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} width="100%" />
//               </div>
//               <div className="winner-tit">{item.name}</div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );

//   return (
//     <>
//       <Header />
//       <div className="container-fluid page-bgs">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-6">
//               <div className="pain-win-yrs">
//                 <div className="win-list-box">
                  
//                   <div className="pain-yrs zoombt">
//                     <Link to="paintathon-drawing-competition-2021"
//                     onClick={() => handleYearChange('2021')}
//                     >2021</Link></div>
//                   <div className="pain-yrs zoombt">
//                     <Link to="paintathon-drawing-competition-2022"
//                     onClick={() => handleYearChange('2022')}>2022</Link></div>
//                   <div className="pain-yrs zoombt yrs24">
//                     <Link to="#"  onClick={() => handleYearChange('2024')}>2024</Link></div>
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-6">
//               <div className="pain-win-list">
//                 <div className="mega-win-1st">
//                   {megaImage && (
//                     <div onClick={() => handleImageClick(megaImage.drawing ) } style={{ cursor: 'pointer' }}>
//                       <div className="win-tit">Winner's List</div>
//                       <h3 className="mt-3">Mega Winner</h3>
//                       <div className="megawin">
//                         <img src={`https://assets.etvbalbharat.com/${megaImage.image}`} alt={megaImage.name} width="100%" />
//                       </div>
//                       <div className="winner-tit" > {megaImage.name}</div>
//                     </div>
//                   )}

//                   <ImageGroup title="Group A" group={response.groupA} />
//                   <ImageGroup title="Group B" group={response.groupB} />
//                   <ImageGroup title="Group C" group={response.groupC} />
//               <div className="col-md-12">
// 							<span className="art-gal">
//                 <Link to ="paintathon-artist-gallerylist-2024">Artist Gallery</Link></span>
// 						</div>
//                 </div>
//               </div>
//               <div className="paintbrush-bk-main">
//               <div className="paintboxmain">
//                 <img src={paintbrush} alt="paintbrush" />
//               </div>
//               <div className="bk-btn zoombt">
//                 <a href="/events">Back to Main Menu</a>
//               </div>
//             </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Modal for displaying the drawing */}
//       {showModal && (
//         <div className="modal fade show"  style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" onClick={closeModal}>
//           <div className="modal-dialog modal-dialog-centered" role="document">
//             <div className="modal-content paintathon-modal-bg">
//               <div className="modal-header modal-he-adj">
//                 <button type="button" className="close close-bg" onClick={closeModal} aria-label="Close">
//                   <span aria-hidden="true">&times;</span>
//                 </button>
//               </div>
//               <div className="modal-body nopd">
//                 <div className="drws">
//                   {selectedDrawing && (
//                     <>
//                       <img src={`https://assets.etvbalbharat.com/${selectedDrawing}`} width="100%" alt="Drawing" />
//                       <div className="drw-cand-name">
//                       {selectedDrawing === megaImage?.drawing
//                       ? megaImage?.name
//                       : [
//                         ...response.groupA,
//                         ...response.groupB,
//                         ...response.groupC,
//                       ].find((item) => item.drawing === selectedDrawing)?.name}
//                 </div>
//                     </>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       <Footer />
//     </>
//   );
// }






































































