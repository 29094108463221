import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_URL_CONSTANT } from '../../constants/routerUrlConstant';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';
import  { nextpage, nextbutton, volumeIcon, muteIcon } from '../../constants/iconImageConstant';

export function PaintathonNextPage() {

  const [isMuted, setIsMuted] = useState(false);
  const audioRef = React.useRef<HTMLAudioElement>(null);

  const handleMuteToggle = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className='paintathon_img'>
      <Header />
      <audio id="paintathonAudio" autoPlay ref={audioRef}>
        <source
          src="https://assets.etvbalbharat.com/video/Channel_Song_MashUp _ETV_Bal_Bharat.mp3"
          type="audio/mp3"
        />
      </audio>
  
     {/* Mute/Unmute Button */}
     <button className="audio " id="muteButton" onClick={handleMuteToggle}>
        <img className='ad-bg'
          id="muteIcon"
          src={isMuted ? muteIcon : volumeIcon}
          alt="Mute/Unmute Icon"
        />
      </button>

      <div className="container-fluid">
        <div className="row">
          <img src={nextpage} width="100%" alt="paintathon" />
          <div className="pain-btn">
            <Link
              to={ROUTER_URL_CONSTANT.PAINT_WIN_LIST_2021}
              className="link-next"
            >
              <img src={nextbutton} alt="paintathon-btn" width="160" />
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}










// wihout music code  21/11/2024 Added by MC 

// import React from 'react';
// import { Link } from 'react-router-dom';
// import { ROUTER_URL_CONSTANT } from '../../constants/routerUrlConstant';
// import { Header } from '../Header/Header';
// import { Footer } from '../appFooter/Footer';
// import { nextpage, nextbutton } from '../../constants/iconImageConstant';

// export function PaintathonNextPage() {
//   return (
//     <div className='paintathon_img'>
//       <Header />
//       <div className="container-fluid">
//         <div className="row">
//           <img src={nextpage} width="100%" alt="paintathon" />
//           <div className="pain-btn">
//             <Link
//               to={ROUTER_URL_CONSTANT.PAINT_WIN_LIST_2021}
//               className="link-next"
//             >
//               <img src={nextbutton} alt="paintathon-btn" width="160" />
//             </Link>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }



