import { Component } from 'react';
import axios from 'axios';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { API_URL_CONSTANTS } from '../constants/apiUrlConstants';

interface Metadata {
  pages: string;
  title: string;
  keyword: string;
  desc: string;
  id: string;
}

interface Props extends RouteComponentProps {}

interface State {
  metadata: Metadata | null;
}

class MetadataUpdater extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      metadata: null,
    };
  }

  componentDidMount() {
    this.fetchMetadata();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchMetadata();
    }
  }

  fetchMetadata = async () => {
    const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.GET_METADATA;

    try {
      const response = await axios.get<Metadata[]>(url);
      const page = this.getPageName();

      var metadata = response.data.find(
        (item: Metadata) => item.pages.toLowerCase() === page.toLowerCase()
      );

      console.log(page);
      console.log(metadata);

      if (typeof metadata === "undefined") {
        metadata = response.data.find(
          (item: Metadata) => item.id === "89"
        );
      }

          console.log(metadata);
     
      this.setState({ metadata: metadata || null }, () => {
        this.updateMetadata();
      });
    } catch (error) {
    }
  };

  getPageName = () => {
    const { pathname } = this.props.location;
    const page = pathname.split('/')[1];
    return page ? page.charAt(0).toUpperCase() + page.slice(1).toLowerCase() : 'Home'; 
  };

  updateMetadata = () => {
    const { metadata } = this.state; 
    document.title = metadata?.title || 'Etv Balbharat';
    this.updateOrCreateMetaTag('keywords', metadata?.keyword || 'Default Keywords');
    this.updateOrCreateMetaTag('description', metadata?.desc || 'ETV Bal Bharat universe explores the vast array of animated programmes delivering its young viewers a world of kidotainment . The dedicated TV channels for kids as target group in 4 to 14 years of age would delve into wide-ranging genres i.e. action, adventure, comedy, epic, mystery and fantasy with flavours and values that are core to a region yet with a timeless vibe. It imbibes value based Indian content and the animated kids movies available in their own language');
  };

  updateOrCreateMetaTag = (name: string, content: string) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement;

    if (metaTag) {
      metaTag.setAttribute('content', content);
    } else {
      metaTag = document.createElement('meta');
      metaTag.name = name;
      metaTag.content = content;
      document.head.appendChild(metaTag);
    }
  };

  render() {
    return null;
  }
}

export default withRouter(MetadataUpdater);
