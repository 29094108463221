// Diwali sheddule working there popup 
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { closeIcon } from '../../constants/iconImageConstant';
import '../../App.scss'; 

export const CustomModal: React.FC = () => {
  const [show, setShow] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    const showTimer = setTimeout(() => {
      setShow(true);
    }, 1000);

    const hideTimer = setTimeout(() => {
      setShow(false);
    }, 5100);

    return () => {
      clearTimeout(showTimer);
      clearTimeout(hideTimer);
    };
  }, []);
  
  useEffect(() => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth(); 

   console.log(month);
    if ((month === 9 && day >= 27) || (month === 10 && day <= 14)) {
      setShow(true);
      setImageSrc('https://mybb.etvbalbharat.com/rangoli/images/pop-up.png'); 
    } else {
      setShow(true);
      setImageSrc('https://mybb.etvbalbharat.com/banners/homepagepopup.jpg');
    }
  }, []);

  const handleClose = () => setShow(false);

  const handleImageClick = () => {
    if (imageSrc.includes('rangoli/images/pop-up.png')) {
      window.location.assign('https://mybb.etvbalbharat.com/rangoli/index.php');
    } else if (imageSrc.includes('banners/homepagepopup.jpg')) {

      window.location.assign('https://mybb.etvbalbharat.com/kids_birthdates_2023/contest2.php');
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={true}
      centered
      className={`custom-modal ${show ? 'show' : 'hide'}`} 
    >
      <Modal.Body>
        <div className="close-btn float-right" role="button" onClick={handleClose} tabIndex={0}>
          <img src={closeIcon} alt="close" width="30px" height="30px" />
        </div>
        <div style={{ textAlign: "center", margin: "0px" }}>
      
          <button onClick={handleImageClick} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
            <img
              src={imageSrc}
              width="100%"
              alt="Diwali Rangowali"
            
            />
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};






